//IMPORTAR O COMPONENTE
import { CreateLambdaComplement } from "./CreateLambdaComplement";
import { CreateTablesComplement } from "./CreateTablesComplement";
import { TableComplement } from "./TableComplement";
import { TableGridLambda } from "./TableGridLambda";
import { ValidatePage } from './ValidatePage'
import { TableNewDownloader } from './TableNewDownloader'
import { SelectCustom } from "./SelectCustom";
import { CheckCustom } from "./CheckCustom";
import { OrderCustom } from "./OrderCustom";
import { TableStockCustom } from "./TableStockCustom"
import { DashboardReportCustom } from "./DashboardReportCustom";
import { ReportCustom } from "./ReportCustom";
import { CheckTreePickerAllWorkshops } from "../CheckTreePickerAllWorkshops";
import { CheckTreePickerAllDashboard } from "../CheckTreePickerAllDashboard";
import { ChangePassword } from "./ChangePassword";

export default {
    ChangePassword,
    TableStockCustom,
    ReportCustom,
    CreateLambdaComplement,
    CreateTablesComplement,
    TableComplement,
    TableGridLambda,
    ValidatePage,
    TableNewDownloader,
    SelectCustom,
    CheckCustom,
    OrderCustom,
    DashboardReportCustom,
    CheckTreePickerAllWorkshops,
    CheckTreePickerAllDashboard
// EXPORTAR O COMPONENTE
};