import axios from "../utilities/axios"

export const mailConfigs : any = {
    estimate: {
        subject: "Envio orçamento para {{nome}}",
        content: `
            <strong>Olá, {{nome}}</strong>
        `,
        events: {
            onInit: (props: any,doneCallback : any) => {
                axios.get('/api/v1/')
            }
        }
    }
}