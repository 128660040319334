import React from "react";
import { Loader, Notification, Tooltip, Whisper } from "rsuite";
import { ConfirmOverlay } from "../../../components/confirmOverlay/ConfirmOverlay";

export class OrderCustom extends React.Component<any, {}> {

    render() {
        return (
            <>
               AAAAAAAAAAAAAAAAAAAAAAAAA
            </>
        );
    };
}
