import classNames from "classnames";
import React from "react";

class Day extends React.Component<any, {}> {
    render() {
        return (
            <div
                onClick={() => this.props.onClick(this.props.number)}
                className={classNames("calendar-day", this.props.weekday.weekend === true ? "weekend" : "", this.props.active ? "active" : "")}>
                {this.props.number}
            </div>
        );
    }
}

interface ICalendarProps {
    days: number;
    startDay?: number;
    values?: number[] | string[];
    onChange?: Function;
}

class Calendar extends React.Component<ICalendarProps, {}> {
    public state: any = {
        activeDays: this.props.values ?? [],
    };

    public weekDays: any = {
        dom: { label: "Domingo", short: "DOM", weekend: true },
        seg: { label: "Segunda", short: "SEG" },
        ter: { label: "Terça", short: "TER" },
        qua: { label: "Quarta", short: "QUA" },
        qui: { label: "Quinta", short: "QUI" },
        sex: { label: "Sexta", short: "SEX" },
        sab: { label: "Sábado", short: "SAB", weekend: true },
    };

    renderWeeks() {
        const output = [];
        for (var index = 0; index < Math.ceil(this.props.days / 7); index++) {
            output.push(<div className="calendar-week">{this.renderDays(index)}</div>);
        }
        return output;
    }

    onClick = (value: number) => {
        const activeDays: number[] = this.state.activeDays;
        if (activeDays.includes(value)) {
            activeDays.splice(activeDays.indexOf(value), 1);
        } else {
            activeDays.push(value);
        }
        this.setState({ activeDays });
        if (this.props.onChange) {
            this.props.onChange(activeDays);
        }
    };

    renderDays(minDay = 0) {
        const day = minDay * 7;
        const maxDays = day + 7;
        const output = [];
        for (var index = day; index < maxDays; index++) {
            if (index > this.props.days - 1) {
                continue;
            }
            const weekday = Object.values(this.weekDays)[index % 7];
            const day = <Day active={this.state.activeDays.includes(index + 1)} onClick={this.onClick} weekday={weekday} number={index + 1} key={index} />;
            output.push(day);
        }
        return output;
    }

    renderHeader() {
        const output = [];
        for (var index in this.weekDays) {
            const weekDay = this.weekDays[index];
            output.push(
                <div className="calendar-header" key={index}>
                    {weekDay.short}
                </div>,
            );
        }
        return <div className="calendar-headers">{output}</div>;
    }

    render() {
        return (
            <div className="calendar-wrapper">
                {/* {this.renderHeader()} */}
                {this.renderWeeks()}
                {/* {JSON.stringify(abc)} */}
            </div>
        );
    }
}

export class DayPicker extends React.Component<any, {}> {
    render() {
        return (
            <div>
                <Calendar values={this.props.values} onChange={this.props.onChange} startDay={3} days={this.props.days} />
            </div>
        );
    }
}
