import axios from "../../utilities/axios";
import React from "react";
import { Button, HelpBlock, Notification } from "rsuite";
import { Event } from "../Event";
import { withRouter } from "react-router";
import { CustomModal } from "../../components/customModal/CustomModal"
import { WYSIWYG } from "../../components/formfields/WYSIWYG";

class StockEntrieFooterComponent extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        motiveContent: '',
        EntryType: this.props.values.formValues.stock_entrie_release_type
    };

    componentDidUpdate() {

    }

    componentDidMount() {

    }

    sendCancelMotive = () => {
        console.log('Props :::', this.props.values.formValues);
        this.setState({ loading: true });
        if (this.state.motiveContent || this.state.motiveContent != '') {
            axios
                .post(`/api/v1/stock-entry/cancelEntry/${this.props.match.params.id}`, {
                    stock_entrie_cancel_motive: this.state.motiveContent
                })
                .then((res) => {
                    if (res.data) {
                        console.log(res.data);
                    }
                })
                .finally(() => {
                    this.setState({ loading: false, show: false });
                    this.props.history.push(`/dashboard/menus/stock_entries`)
                });
        } else {
            Notification.info({
                title: "O motivo do cancelamento é obrigatório!",
                placement: "bottomEnd",
            });
            this.setState({ loading: false })
        }
    }

    render() {

        return (
            <div>

                <CustomModal
                    show={this.state.show}
                    tableOmie={false}
                    title={<>
                        <i className="fas fa-exclamation-triangle" style={{ marginTop: "7px", marginRight: "10px" }}></i>
                        Motivo do Cancelamento
                    </>}
                    body={
                        <>
                            <WYSIWYG onChange={(value: any) => this.setState({ motiveContent: value })} value={this.state.motiveContent} />
                            <HelpBlock>Obrigatório</HelpBlock>
                        </>
                    }
                    onClose={() => {
                        this.setState({ show: false });
                    }}
                    showConfirm={true}
                    cancelText={"Fechar"}
                    onConfirm={this.sendCancelMotive}
                    confirmColor={"green"}
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({ show: false });
                    }}
                />

                {console.log("is entrie", this.props.values.formValues.stock_entrie_is_entrie)}
                {(this.state.EntryType === 'E' && this.props.values.formValues.stock_entrie_is_entrie && !this.props.values.formValues.stock_entrie_canceled_at) && (
                    <Button loading={this.state.loading} onClick={() => this.setState({ show: true })} color={"red"} className="ml-4">
                        <i className="fas fa-times-circle fa-fw mr-2"></i>
                        Cancelar
                    </Button>
                )}

            </div>
        );
    }
}

const StockEntrieFooterWithRouter = withRouter(StockEntrieFooterComponent);

export class StockEntrieFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <StockEntrieFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}