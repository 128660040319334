import React from "react";
import { Loader, Notification, Tooltip, Whisper } from "rsuite";
import { ConfirmOverlay } from "../../../components/confirmOverlay/ConfirmOverlay";

class PsmRegistrationProductsActions extends React.Component<any, {}> {
    public state = {
        rowData: this.props.rowData,
        aprovado: false,
        loading: false,
        showConfirm: false,
    };

    public triggerRef: any = null;

    componentDidMount() {
        this.triggerRef = React.createRef();
    }

    aprovar = () => {
        this.setState({ loading: true });
        //TODO: chamar api ao em vez disso aqui né porra 
        setTimeout(() => {
            Notification.success({
                title: "Sucesso!",
                description: "Item aprovado com sucesso!",
            });
            this.setState({ rowData: { ...this.state.rowData, aprovado: true }, loading: false });
        }, 2000);
    };

    render() {
        return (
            <>
                {this.state.rowData.psm_registration_product_id !== undefined && (
                    <>
                        {this.state.loading == false && this.state.rowData.aprovado !== true && (
                            <>
                                <Whisper trigger={"hover"} placement="top" speaker={<Tooltip>Aprovar</Tooltip>}>
                                    <i onClick={this.aprovar} className="far text-success fa-fw clickable fa-check-circle"></i>
                                </Whisper>
                            </>
                        )}
                        {this.state.loading == false && this.state.rowData.aprovado == true && (
                            <>
                                <Whisper trigger={"hover"} placement="top" speaker={<Tooltip>Aprovado!</Tooltip>}>
                                    <i className="fas text-success fa-fw fa-check-circle"></i>
                                </Whisper>
                            </>
                        )}
                        {this.state.loading == true && <Loader size={"sm"} speed="slow" />}
                    </>
                )}
            </>
        );
    }
}

export class PsmRegistrationProductEvents {
    public width = 300;

    public getActions = (rowData: any) => {
        return (
            <>
                <PsmRegistrationProductsActions rowData={rowData} />
            </>
        );
    };
}
