import React from "react";
import { Alert, Button, Loader, Panel, Steps, Uploader } from "rsuite";
import config from "../../globals";
import axios from "../../utilities/axios";
import { AuthContext } from "../authProvider/main";
import { ComparisonTable } from "./ComparisonTable";

interface ExportImportStepsState {
    isDownloadingFile: boolean;
    isValidatingFile: boolean;
    isUploadingData: boolean;
    processDone: boolean;
    step: number;
    activeFile: string | null;
    validatedResult: any;
}
export class ExportImportSteps extends React.Component<any, ExportImportStepsState> {
    static contextType = AuthContext;

    public state : ExportImportStepsState = {
        isDownloadingFile: false,
        isValidatingFile: true,
        isUploadingData: true,
        processDone: false,
        step: this.props.step ? this.props.step : 0,
        activeFile: "/storage/uploads/file.xlsx",
        validatedResult: {},
    };

    downloadFile = () => {
        this.setState({ isDownloadingFile: true });
        axios
            .get("/api/v1/get-import/" + this.props.crud, {
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.setState({ isDownloadingFile: false, step: this.state.step + 1 });
            })
            .catch(() => {
                Alert.error("Falha ao gerar arquivo XLSX");
                this.setState({ isDownloadingFile: false });
            });
    };

    onSuccess = (data: any) => {
        if (data.path) {
            this.setState({ step: this.state.step + 1, activeFile: data.path, isValidatingFile: true });
            axios.post("/api/v1/validate-import/" + this.props.crud, { file: data.path }).then((res) => {
                if (res && res.data) {
                    //
                    this.setState({ isValidatingFile: false, validatedResult: res.data });
                }
            });
        }
        // 
    };

    addRowsToDB = () => {
        this.setState({ isUploadingData: true });
        axios
            .post("/api/v1/import/" + this.props.crud, { data: this.state.validatedResult })
            .then((res) => {
                // this.props.close();
                this.setState({ processDone: true, isUploadingData: false });
            })
            .catch((e) => {
                Alert.error(e.message);
                this.setState({ isUploadingData: false });
            });
    };

    renderSteps = () => {
        var steps = [
            <Panel>
                Faça o download do arquivo abaixo e faça as modificações necessarias
                <div className="d-flex justify-content-between w-100 mt-4">
                    <Button loading={this.state.isDownloadingFile} onClick={this.downloadFile} color={"green"}>
                        <i className="fas fa-fw fa-download mr-2"></i>Download
                    </Button>
                    <Button onClick={() => this.setState({ step: this.state.step + 1 })} color={"orange"}>
                        <i className="fas fa-fw fa-upload mr-2"></i>Já possuo o arquivo
                    </Button>
                </div>
            </Panel>,
            <Panel>
                <div className="d-flex align-items-center justify-content-center flex-direction-column mt-4">
                    <Uploader
                        onSuccess={this.onSuccess}
                        headers={{
                            authorization: "bearer " + this.context.token,
                        }}
                        action={config.url + "/api/v1/files/upload/import-excel"}
                        className="w-100"
                        draggable>
                        <div style={{ lineHeight: 5 }}> Clique ou arraste arquivos aqui para enviar.</div>
                    </Uploader>
                    <div className="d-flex justify-content-between w-100">
                        <Button color={"blue"} onClick={() => this.setState({ step: this.state.step - 1 })}>
                            <i className="fas fa-fw fa-chevron-left"></i>Voltar
                        </Button>
                        <Button color={"green"} disabled>
                            <i className="fas fa-fw fa-chevron-right"></i> Continuar
                        </Button>
                    </div>
                </div>
            </Panel>,
            <Panel>
                <div className="d-flex justify-content-center">
                    {this.state.isValidatingFile === true && <Loader size={"sm"} content="Validando..."></Loader>}
                    {this.state.isValidatingFile === false && Object.keys(this.state.validatedResult).length > 1 && <ComparisonTable data={this.state.validatedResult} />}
                </div>
                <div className="d-flex justify-content-between w-100">
                    <Button color={"blue"} onClick={() => this.setState({ step: this.state.step - 1 })}>
                        <i className="fas fa-fw fa-chevron-left"></i>Voltar
                    </Button>
                    {/* {Object.keys(this.state.validatedResult.rows).length} */}
                    <Button
                        color={"green"}
                        disabled={Object.keys(this.state.validatedResult.rows ? this.state.validatedResult.rows : {}).length <= 0}
                        onClick={() => {
                            this.setState({ step: this.state.step + 1 });
                            this.addRowsToDB();
                        }}>
                        <i className="fas fa-fw fa-chevron-right"></i> Continuar
                    </Button>
                </div>
            </Panel>,
            <Panel>
                <div className="d-flex justify-content-center mb-3">{this.state.isUploadingData === true && <Loader size={"sm"} content="Enviando..."></Loader>}</div>
                {this.state.processDone === true && (
                    <div>
                        <div className="d-flex justify-content-center mt-4 mb-4 w-100">
                            <h4>
                                Importação realizada com{" "}
                                <span className="text-success ml-2 mr-2">
                                    Sucesso <i className="fas fa-check"></i>
                                </span>{" "}
                                você ja pode fechar essa tela!
                            </h4>
                        </div>
                        <div className="d-flex justify-content-center mt-8 w-100">
                            <Button color={"green"} onClick={this.props.onComplete} disabled={this.state.isUploadingData}>
                                <i className="fas fa-fw fa-check"></i> Finalizar
                            </Button>
                        </div>
                    </div>
                )}
            </Panel>,
        ];
        return steps[this.state.step];
    };

    render() {
        return (
            <>
                <Steps current={this.state.step} small style={{ marginTop: 10 }}>
                    <Steps.Item title="Download"></Steps.Item>
                    <Steps.Item title="Upload"></Steps.Item>
                    <Steps.Item title="Pré-validação"></Steps.Item>
                    <Steps.Item title="Finalização"></Steps.Item>
                </Steps>
                {this.renderSteps()}
                {/* <Panel></Panel> */}
            </>
        );
    }
}
