import React from "react";
import { InputGroup, Loader, Modal, Notification, Tooltip, Whisper } from "rsuite";
import LoadingModal from "../../components/modal/LoadingModal";
import axios from "../../utilities/axios";

export default class ProductStockModal extends React.Component<any, {}> {
    public state = {
        show: false,
    };


    componentDidUpdate(prevProps: any,prevState: any) {
        if(prevProps.value != this.props.value) {
            if(this.props.value !== null) {
                this.loadProductData();
            }
        }
    }

    loadProductData = () => {
        //TODO: Buscar estoque e mostrar no modal
        // axios.get(`/api/products/${this.props.value}/stock`).then((res) => {
            
        // })
    }


    render() {
        return (
            <>
                <Whisper placement="top" speaker={<Tooltip>Estoque</Tooltip>}>
                    <InputGroup.Button onClick={() => this.setState({ show: true })} disabled={this.props.value == null}>
                        <i className="fas fa-fw fa-box"></i>
                    </InputGroup.Button>
                </Whisper>
                <LoadingModal loading={true} show={this.state.show}>
                    <Modal.Header onHide={() => this.setState({show: false})}>Estoque</Modal.Header>
                    <Modal.Body>...</Modal.Body>
                </LoadingModal>
            </>
        );
    }
}