import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";

export class ItemEvents extends Event {
  public onChange = async (value: string, setField: any, setState: any, formValues: any) => {
    console.log('this.global.props', this?.globalProps?.values)
    console.log('value onchange:::', value)
    console.log('formValues:::', formValues)
  }
}
