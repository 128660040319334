import { Event } from "../Event";

export class DevelopmentCheckTablesEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if(value == true){
            this.setState('loadings',[
                "development_table_name"
            ])
            this.setFieldValue("development_table_name", "");

            this.setState("loadings",[])
        }
    };
 
}

export class DevelopmentRedoViewsEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if(value == true){
            this.setState('loadings',[
                "development_table_name"
            ])
            this.setFieldValue("development_table_name", "");

            this.setState("loadings",[])
        }
    };
}

// export class DevelopmentGridCreateEvents extends Event {
//     public onLoad = () => {
//         console.log("Onload")
//     }
// }