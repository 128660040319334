import axios from "../../utilities/axios";
import React from "react";
import _ from "lodash";
import { Loader, MultiCascader } from "rsuite";

export class AxiosCascadePicker extends React.Component<any, {}> {
    public state = {
        data: [],
        uncheckable: [],
        value: this.props.value ?? [],
        loading: true,
    };
    getData = () => {
        return [];
    };

    componentDidMount() {
        this.onSearch();
    }


    checkIfHasChildren = (item: any, outputs: any = []) => {
        if (item.children) {
            outputs = [...outputs, item.value];
            for (var i in item.children) {
                outputs = [...outputs, ...this.checkIfHasChildren(item.children[i])];
            }
        }
        return outputs;
    };

    onChange = (value: string[]) => {
        this.setState({ value });
        if (this.props.onChange) {
            this.props.onChange(value, this.state.data);
        }
    };

    onSearch = (search: string | null = null) => {
        this.setState({loading: true});
        axios.get(this.props.field?.api?.url ?? "/api/v1/storage-local-lines/select").then((res) => {
            if (res.data) {
                const items = res.data.items;
                var uncheckable: string[] = [];
                for (var i in items) {
                    var unchecks = this.checkIfHasChildren(items[i]);
                    // //console.log({resss});
                    uncheckable = [...uncheckable, ...unchecks];
                }
                this.setState({ data: res.data.items, uncheckable });
            }
        }).catch((e) => {

        }).finally(() => {
            this.setState({loading: false});
        });
    };

    getFullNamePath = (item: string[]) => {
        var result = [];
        var index = '';
        if (this.state.data) {
            var activeObj: any = null;
            for (var i in item) {
                var id = item[i];
                if (activeObj === null) {
                    activeObj = _.find(this.state.data, { value: id });
                    index += id;
                    if(activeObj?.label) {
                    result.push(activeObj?.label);
                    }
                    // //console.log({ activeObj,data: this.state.data,find: _.find(this.state.data, { value: id }),id});
                } else {
                    index += '-'+id;
                    //console.log({activeObj: activeObj?.children,index});
                    // //console.log({ activeObj,data: this.state.data,find: _.find(this.state.data, { value: id }),id});
                    activeObj = _.find(activeObj?.children ?? [],{value: index})
                    if(activeObj?.label) {
                        result.push(activeObj?.label)
                    }
                    
                }
                
            }
        }
        return result;
    };

    renderValue = (data: any) => {
        if(this.state.loading) {
            return <span style={{height: 20}}><Loader style={{height: 1}} content={"Carregando..."} /></span>;
        }
        var names = [];
        for (var i in data) {
            names.push(this.getFullNamePath(data[i].split("-")));
        }
        return <span style={{width:'100%'}}>{names.map((item,mainIndex) => {
            return <span style={{overflow:'hidden',textOverflow:"ellipsis",display:'block'}}>{item.map((subitem,index) => {
                return subitem + (index < item.length -1 ? ' / ' : '')
            })}{mainIndex < names.length -1 && <hr style={{margin:0,marginBottom: 4,borderColor:'#0e4194',opacity:0.3}} />}</span>
        })}</span>;
    };

    render() {
        return (
            <>
                <MultiCascader
                    disabled={this.props.disabled}
                    renderValue={this.renderValue}
                    cascade={false}
                    placeholder={"Selecione..."}
                    value={this.state.value}
                    onChange={this.onChange}
                    preventOverflow={true}
                    uncheckableItemValues={this.state.uncheckable}
                    onOpen={this.onSearch}
                    data={this.state.data}
                />
            </>
        );
    }
}
