import Axios from "axios";
import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserUsernameEvents extends Event {
    onBlur = (value: any, setField: any, setState: any) => {
        if (value.length === 14) { // cnpj
            this.setField("user_fantasy_name", { hidden: false });

            setState("loadings", [
                "user_name",
                "user_fantasy_name",
                "user_address_cep",
                "user_address_fk_state_id",
                "user_address_fk_city_id",
                "user_address_street",
                "user_address_number",
                "user_address_district",
                "user_email"
            ]);

            axios
                .get("/api/v1/consulta-cnpj/" + value)
                .then((res: any) => {
                    const { data } = res.data;
                    console.log("res.data do consulta cnpj", data);

                    if (data.status === "ERROR") {
                        setState("loadings", []);
                        return;
                    }
                    if (data) {
                        // console.log("consulta cnpj", data)
                        if (data.fantasia.length) {
                            setField("user_fantasy_name", data.fantasia.length ? data.fantasia : data.nome);
                        }
                        setField("user_name", data.nome);
                        setField("user_email", data.email);
                        setField("user_address_cep", data.cep.split("-").join(""));

                        Axios
                            .get("https://viacep.com.br/ws/" + data.cep.split("-").join("").split(".").join("") + "/json/")
                            .then((res: any) => {
                                let stateId: any = undefined;
                                axios
                                    .get("/api/v1/states/select", {
                                        params: {
                                            state: res.data.uf,
                                        },
                                    })
                                    .then((res) => {
                                        const { items } = res.data;
                                        const { label, value } = items[0] ?? undefined;
                                        stateId = value;
                                        if (label && value) {
                                            setField("user_address_fk_state_id", { label, value: value.toString() });
                                        }
                                    })
                                    .catch((e) => { })
                                    .finally(() => {
                                        axios
                                            .get("/api/v1/cities/select", {
                                                params: {
                                                    state: stateId,
                                                    city_ibge_code: res.data.ibge
                                                },
                                            })
                                            .then((res) => {
                                                var { items } = res.data;
                                                var { label, value } = items[0] ?? undefined;
                                                // console.log("label e values", label, value);
                                                if (label && value) {
                                                    this.setFieldValue("user_address_fk_city_id", value);
                                                }
                                            })
                                            .catch((e) => { })
                                            .finally(() => {
                                                setState("loadings", []);
                                            });
                                    });

                                setField("user_address_street", data.logradouro);
                                setField("user_address_number", data.numero);
                                setField("user_address_district", data.bairro);
                            })
                    }
                })
                .catch((e) => {
                    setState("loadings", []);
                })
                .finally(() => {
                    // setState("loadings", []);
                });
        }
    };
}