import { Event } from "../Event";
import axios from "../../utilities/axios";

export class ServiceOrderEvents extends Event {
    onLoad = async (value: any, setField: any, setState: any, formValues: any) => { // so vai cancelar pelo celular
        if (this.globalProps.values.service_order_cancel_motive == null || this.globalProps.values.service_order_cancel_motive === "") {
            this.setField("service_order_cancel_motive", { hidden: true });
        }
        if (
            this.globalProps.values.service_order_state_fk_situation_id === 16 ||
            this.globalProps.values.service_order_state_fk_situation_id === 13
        ) {
            await axios.post("api/v1/service_orders/", { id: this.globalProps.values.service_order_id }).then((res) => {
                this.globalProps.values.service_order_hidden_id = String(res.data.situation)
            })
        }

        // const currentSituation = this.globalProps.values.service_order_state_fk_situation_id;
        // this.setField("service_order_state_fk_situation_id", {
        //     api: {
        //         ...this.globalProps.form.service_order_state_fk_situation_id.api,
        //         url: `/api/v1/situations/select?initialValue=${currentSituation}`
        //     }
        // });

        // if (currentSituation === 45) {
        //     this.setField("service_order_state_fk_situation_id", { readonly: true });
        // }
    };
}