import React from "react";
import { Table, SelectPicker, Loader, CheckPicker } from "rsuite";
import axios from "../../../utilities/axios";
import _, { result } from "lodash";

interface RSuiteComponent {
    label: string
    value: number
}

interface State {
    data: Array<RSuiteComponent>
    user: null | number
    loading: boolean,
    companies: Array<RSuiteComponent>
    selectedCompanies: Array<number>
    showCompanies: boolean
}

export class SelectCustom extends React.Component<any, {}> {
    public state: State = {
        data: [],
        user: null,
        loading: false,
        companies: [],
        selectedCompanies: [],
        showCompanies: false
    }

    async findRoles() {
        this.setState({ loading: true })
        await axios.get('api/v1/roles')
            .then((res: any) => {
                this.setState({
                    data: res.data.data
                })
            })
            .catch((e) => console.log(e.message))
            .finally(() => this.setState({ loading: false }))
    }

    // async findCompanies() {
    //     this.setState({ loading: true })
    //     await axios.get('api/v1/companies/select?CheckTreePicker=true')
    //         .then((res: any) => {
    //             const companies = res.data.items.map((result: any) => ({
    //                 label: result.label,
    //                 value: result.value
    //             }))
    //             this.setState({
    //                 companies: companies
    //             })
    //         })
    //         .catch((e) => console.log(e.message))
    //         .finally(() => this.setState({ loading: false }))
    // }

    updateData = () => {
        if (this.state.data.length === 0) {
            this.findRoles();
        }
    };

    renderMenu = (menu: any) => {
        if (this.state.loading) {
            return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <Loader/> Carregando...
                </p>
            );
        }
        return menu;
    };

    componentDidMount() {
        this.findRoles()
        // this.findCompanies()
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.user !== prevState.user) {
            const showCompanies = this.state.user === 4 || this.state.user === 2;
            if (showCompanies !== this.state.showCompanies) {
                this.setState({ showCompanies });
            }
        }
    }

    render() {
        const { data, user, showCompanies, companies } = this.state
        return (
            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
                <p>Função</p>
                <SelectPicker
                    onChange={(value: number) => {
                        this.setState({ user: value })
                        this.props.onChange(value)
                    }}
                    placeholder="Selecione..."
                    data={data}
                    value={user}
                    style={{ marginBottom: 30 }}
                    onOpen={this.updateData}
                    onSearch={this.updateData}
                    renderMenu={this.renderMenu}
                />
                {/* {showCompanies && (
                    <>
                        <p>Empresas que o usuário pode acessar</p>
                        <CheckPicker
                            onChange={(value: any) => {
                                this.setState({ selectedCompanies: value })
                                this.props.onChange(value)
                            }}
                            data={companies}
                            placeholder={"Empresas"}
                            style={{ marginBottom: 20 }}
                        />
                    </>
                )} */}
            </div>
        )
    }
}