import React from "react";
import { Button, Table, DatePicker, SelectPicker, Alert, Progress, Notification, Input, Checkbox, CheckTreePicker, InputNumber } from "rsuite";
import axios from "../../utilities/axios";
import _, { filter } from "lodash";
import TablePagination from "rsuite/lib/Table/TablePagination";
import dayjs from "dayjs";
import { CheckPicker } from 'rsuite';
import axiosReport from "../../utilities/axiosReport";

const { Column, HeaderCell, Cell } = Table;
export class ReportBalance extends React.Component<any, {}> {
  public state: any = {
    data: [],
    dateIni: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
    endDate: dayjs().format('MM/DD/YYYY'),
    loading: false,
    plate: "",
    osNumber: "",
    selectedWorkshop: null,
    city: null,
    checked: false,
    showFieldsReport: [
      "stock_entrie_fk_city_id",
      "stock_entrie_user_fk_user_id",
      "stock_entrie_tire_measurement_fk_tire_measurement_id",
      "stock_entrie_created_at",
      "stock_entrie_release_type",
      "stock_entrie_quantity",
      "stock_entrie_origin",
      "stock_entrie_service_request_number"
    ],
    filterValues: {
      stock_entrie_created_at_init: {
        "field": "stock_entrie_created_at",
        "op": "gte",
        // "op": "",
        "value": dayjs().subtract(30, 'days').format('YYYY-MM-DD 00:00:00.000')

      },
      stock_entrie_created_end: {
        "field": "stock_entrie_created_at",
        "op": "lte",
        // "op": "",
        "value": dayjs().format('YYYY-MM-DD 23:59:59.999')
      },
      stock_entrie_fk_city_id: {
        "field": "stock_entrie_fk_city_id",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      stock_entrie_user_fk_user_id: {
        "field": "stock_entrie_user_fk_user_id",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      stock_entrie_tire_measurement_fk_tire_measurement_id: {
        "field": "stock_entrie_tire_measurement_fk_tire_measurement_id",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      service_order_plate: {
        "field": "service_order_plate",
        "op": "",
        "value": ''
      },
      // service_order_id: {
      //   "field": "service_order_id",
      //   "op": "",
      //   "value": ''
      // },
      stock_entrie_os_fk_service_order_id: {
        "field": "stock_entrie_os_fk_service_order_id",
        "op": "",
        "value": ''
      },
      stock_entrie_service_request_number: {
        "field": "stock_entrie_service_request_number",
        "op": "",
        "value": ''
      },
      stock_entrie_release_type: {
        "field": "stock_entrie_release_type",
        // "op": "andWhere",
        "op": "",
        "value": ''
      }
    },
  }

  public locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
  };

  handleReport = async () => {
    this.setState({ loading: true })
    await axiosReport
      .post(`/api/v1/reports/generate`, { params: Object.values(this.state.filterValues), crud: "Balance", type: "report_movement_stock", showFieldsReport: this.state.showFieldsReport, checked: this.state.checked })
      .then((res) => {
        // console.log(res.data);
        this.setState({ data: res.data.outputUrl });
      })
      .catch((e) => {
        Notification.error({
          title: 'Erro!',
          description: e.response.data.message
        })
        this.setState({ loading: false });
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  }

  onChange = (alias: any, field: any, op = "", value: any) => {
    this.setState({ filterValues: { ...this.state.filterValues, [alias]: { field: field, op: op, value: value } } })
  };

  handleChecked = (event: any) => {
    this.setState({
      checked: !event
    });
  }

  findWorkshopsByCityID = async (city_id: any) => {
    // console.log(city_id);
    await axios
      .get(`api/v1/reports/workshopsByCityID/${city_id}`)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ workshops: res.data.data })
        }
      })
      .catch((e) => console.log(e.message))
  }

  // findTireMeasurementByUserID = async (user_id: any) => {
  //   // console.log(user_id);
  //   await axios
  //     .get(`api/v1/reports/tireMeasurementByUserID/${user_id}`)
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         this.setState({ tireMeasurements: res.data.data })
  //       }
  //     })
  //     .catch((e) => console.log(e.message))
  // }

  HeaderSummary = ({ title, summary }: any) => {
    return (
      <div>
        <label>{title}</label>
        <div
          style={{
            fontSize: 18,
            color: summary < 0 ? 'red' : '#2eabdf'
          }}
        >
          Total = {summary}
        </div>
      </div>
    )
  };

  NumberCell = ({ rowData, dataKey, ...props }: any) => {
    return (
      <Cell {...props}>{rowData[dataKey]}</Cell>
    )
  }

  render() {
    const { tireMeasurements, workshops, cities } = this.props

    let totalQuantity = 0;
    if (this.state.data) {
      this.state.data.forEach((item: any) => {
        totalQuantity += item.stock_entrie_quantity;
      });
    }

    return (
      <>
        <div style={{ display: 'flex', flexDirection: "column", flexWrap: 'wrap', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>Cidade</div>
              <CheckPicker
                data={cities}
                placeholder={"Selecione..."}
                onClean={() => this.setState({ workshops: workshops })}
                onSelect={(event) => this.findWorkshopsByCityID(event)}
                onChange={(event) => {
                  this.onChange("stock_entrie_fk_city_id", "stock_entrie_fk_city_id", "inlist", event);
                  // this.setState({ city: event })
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '39.8%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>Nome Fantasia (Oficina)</div>
              <CheckPicker
                data={this.state.workshops || workshops}
                placeholder={"Selecione..."}
                // onSelect={(event) => this.findTireMeasurementByUserID(event)}
                onChange={(event: any) => {
                  this.onChange("stock_entrie_user_fk_user_id", "stock_entrie_user_fk_user_id", "inlist", event)
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '30%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>Medida</div>
              <CheckPicker
                data={this.state.tireMeasurements || tireMeasurements}
                placeholder={"Selecione..."}
                onChange={(event) => {
                  this.onChange("stock_entrie_tire_measurement_fk_tire_measurement_id", "stock_entrie_tire_measurement_fk_tire_measurement_id", "inlist", event)
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '16%', marginTop: 16 }}>
              <Button
                color="green"
                loading={this.state.loading}
                onClick={() => this.handleReport()}>Gerar Relatório</Button>
            </div>
            {this.state.data.length !== 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 16 }}>
                <Table
                  height={400}
                  headerHeight={60}
                  data={this.state.data}
                  rowKey="tire_measurement_id"
                  isTree
                  defaultExpandAllRows
                  virtualized
                  wordWrap
                >
                  <Column width={475} resizable>
                    <HeaderCell>Oficina</HeaderCell>
                    <Cell dataKey="user_fantasy_name" />
                  </Column>

                  <Column width={400} resizable>
                    <HeaderCell>Medida</HeaderCell>
                    <Cell dataKey="tire_measurement_description" />
                  </Column>

                  <Column width={125} align="center" fixed>
                    <HeaderCell>Quantidade Total</HeaderCell>
                    <Cell style={{ color: "blue" }} dataKey="stock_entrie_quantity" />
                  </Column>
                </Table>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}