import React from "react";
import { Notification, Panel, PanelGroup, Loader } from "rsuite";
import axios from "../../../utilities/axios";
import dayjs from "dayjs";
import { SelectTireMeasurementCustom } from "./SelectTireMeasurementCustom";

export class TableStockCustom extends React.Component<any, {}> {
    public state: any = {
        loading: true,
        data: []
    }

    findStocks = async () => {
        const url = `api/v1/stocks/list?page=1&perPage=999`;
        await axios.get(url).then((res: any) => {
            let resData: any = res.data.items;
            console.log(resData)
            resData = resData.filter((item: any) => item.stock_mechanics_fk_user_id === parseInt(this.props.match.params.id));
            console.log({ resData });
            // const totalPages = res.data.pagination.total;
            const groupByDate = (data: any) => {
                return data.reduce((groupedData: any, current: any) => {
                    const date = current['stock_created_at'].split('T')[0];
                    if (groupedData[date]) {
                        groupedData[date].push(current);
                    } else {
                        groupedData[date] = [current];
                    }
                    return groupedData;
                }, {});
            };

            this.setState({ data: groupByDate(resData) })
        })
            .catch((e) => {
                Notification.error({
                    title: "Error!",
                    description: 'Erro na Listagem!'
                })
            })
            .finally(() => this.setState({ loading: false }))
    }

    componentDidMount(): void {
        this.findStocks()
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Loader size="lg" />
                    </div>
                ) : (
                    <PanelGroup accordion bordered>
                        {Object.keys(this.state.data).length === 0 ? (
                            <Panel>
                                <p>Nenhum registro encontrado.</p>
                            </Panel>
                        ) : (
                            Object.keys(this.state.data).map((date) => (
                                <Panel key={date} header={dayjs(date).format("DD/MM/YYYY")}>
                                    <table style={{ width: "100%", borderCollapse: 'collapse' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ border: '1px solid #ccc', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Data</th>
                                                <th style={{ border: '1px solid #ccc', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Medida do Pneu</th>
                                                <th style={{ border: '1px solid #ccc', padding: '8px', backgroundColor: '#f2f2f2', textAlign: 'left' }}>Quantidade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data[date].map((item: any) => (
                                                <tr key={item.stock_id}>
                                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{dayjs(item.stock_created_at).format("DD/MM/YYYY HH:mm")}</td>
                                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                                                        <SelectTireMeasurementCustom id={item.stock_fk_tire_measurement_id} />
                                                    </td>
                                                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{item.stock_count_amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Panel>
                            ))
                        )}
                    </PanelGroup>
                )}
            </>
        )
    }
}