import { Event } from "../Event";


export class UserAllHubsEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if(value === true){
            this.setField("user_macro_regions", {
                disabled: true
            })
        }
        if(value === false){
            this.setField("user_macro_regions", {
                disabled: false
            })
        }
    }
}