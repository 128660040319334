import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";
// import { Panel } from "rsuite";
import { ModuleContext, UserContext } from "../components/authProvider/main";
import DashboardCustomization from "../components/dashboardCustomization/dashboardCustomization";
import { getModule } from "../globals";

class DashboardPage extends React.Component<any, {}> {
    static contextType = ModuleContext;
    
    public state = {
        module: null,
    };

    componentDidMount() {
        this.context.setPage("/dashboard/stock_reports");
    }

    render() {
        const { module } = this.props.match.params;
        if (module !== null) {
            const moduleConfig = getModule(module);
            // return <div>oi</div>;
            return (
                <div style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }} className={classNames("container")}>
                    <div className="w-100">
                        {/* <div className="col-md-12"> */}

                        <DashboardCustomization module={module}>
                            {/* <Panel bordered style={{ backgroundColor: "#fff" }} header="Dashboard"> */}
                            Bem-vindo(a) ao sistema,&nbsp;
                            <b style={{ textTransform: "capitalize", color: this.context.color }}>
                                <UserContext.Consumer>
                                    {(user: any) => user.data?.user_name}
                                </UserContext.Consumer>
                            </b>
                            {/* {JSON.stringify(this.props.history)} */}
                            {/* </Panel> */}
                            {/* {console.log(DashboardPage.user.Consumer)} */}
                        </DashboardCustomization>
                        {/* </div> */}
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(DashboardPage);
