import React from "react";
import axios from "../../../utilities/axios";

export class SelectTireMeasurementCustom extends React.Component<any, {}> {
    public state: any = {
        label: null
    }

    componentDidMount(): void {
        axios.get(`api/v1/tire_measurements/${this.props.id}`).then((res: any) => {
            this.setState({ label: res.data.item?.tire_measurement_description });
        })
    }

    render() {
        return (
            <>
                {this.state.label}
            </>
        )
    }
}