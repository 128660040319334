import React from "react";

export class Page404 extends React.Component<any, {}> {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 align-items-center justify-content-center d-flex">
                        <img width={"45%"} src={"/404.svg"} alt="" />
                    </div>
                </div>
                {/* <Svg404 /> */}
            </div>
        );
    }
}
