import React from "react";
import { Table, SelectPicker, Loader, CheckPicker } from "rsuite";
import axios from "../../../utilities/axios";
import _, { result } from "lodash";

interface RSuiteComponent {
  label: string
  value: number
}

interface State {
  loading: boolean,
  companies: Array<RSuiteComponent>
  selectedCompanies: Array<number>
}

export class CheckCustom extends React.Component<any, {}> {
  public state: State = {
    loading: false,
    companies: [],
    selectedCompanies: [],
  }

  async findCompanies() {
    this.setState({ loading: true })
    await axios.get('api/v1/companies')
      .then((res: any) => {
        this.setState({
          companies: res.data.items
        })
      })
      .catch((e) => console.log(e.message))
      .finally(() => this.setState({ loading: false }))
  }

  componentDidMount() {
    this.findCompanies()
  }

  render() {
    const { companies } = this.state
    return (
      <div>
        <p>Empresas que o usuário pode acessar</p>
        <CheckPicker
          onChange={(value: any) => {
            this.setState({ selectedCompanies: value })
            this.props.onChange(value)
          }}
          data={companies}
          placeholder={"Empresas"}
          style={{ marginBottom: 20, width: "100%" }}
        />
      </div>
    )
  }
}