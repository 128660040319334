import React from "react";
import ReactApexChart from 'react-apexcharts';
import { Button, CheckPicker, DatePicker, Loader, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import _ from "lodash";
import { CheckTreePickerAllDashboard } from "../CheckTreePickerAllDashboard";
import dayjs from "dayjs";
import axiosReport from "../../../utilities/axiosReport";

export class DashboardReportCustom extends React.Component<any, {}> {
  dataPointSelection = (event: any, chartContext: any, config: any) => {
    const seriesIndex = config.seriesIndex;
    const dataPointIndex = config.dataPointIndex;
    const seriesData = config.w.config.series[seriesIndex].data;
    const clickedValue = seriesData[dataPointIndex];
    this.getTiremesurementId(clickedValue.x)
  }

  public state: any = {
    tireMeasurementId: null,
    generateReport: false,
    loading: false,
    dataLegend: [],
    workshops: [],
    cities: [],
    groups: [],
    data: [],
    selectedWorkshops: [],
    selectedCities: [],
    dateIni: dayjs().subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss.sss'),
    dateEnd: dayjs().format('YYYY-MM-DD HH:mm:ss.sss'),
    widthDashboard: localStorage.getItem('hiddenSidebar') === 'true' ? true : false,
    showFieldsReport: [
      "stock_entrie_fk_city_id",
      "stock_entrie_user_fk_user_id",
      "stock_entrie_tire_measurement_fk_tire_measurement_id",
      "stock_entrie_created_at",
      "stock_entrie_release_type"
    ],
    filterValues: {
      stock_entrie_created_at_init: {
        "field": "stock_entrie_created_at",
        // "op": "gte",
        "op": "",
        "value": ""
      },
      stock_entrie_created_end: {
        "field": "stock_entrie_created_at",
        // "op": "lte",
        "op": "",
        "value": ""
      },
      stock_entrie_fk_city_id: {
        "field": "stock_entrie_fk_city_id",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      stock_entrie_user_fk_user_id: {
        "field": "stock_entrie_user_fk_user_id",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      stock_entrie_tire_measurement_fk_tire_measurement_id: {
        "field": "stock_entrie_tire_measurement_fk_tire_measurement_id",
        "op": "andWhere",
        // "op": "",
        "value": ''
      },
      service_order_plate: {
        "field": "service_order_plate",
        "op": "",
        "value": ''
      },
      service_order_purchase_order_number: {
        "field": "service_order_purchase_order_number",
        "op": "",
        "value": ''
      },
      stock_entrie_release_type: {
        "field": "stock_entrie_release_type",
        // "op": "andWhere",
        "op": "",
        "value": ''
      }
    },
    series: [{
      name: "",
      data: [],
    }],
    options: {
      chart: {
        type: 'bar',
        events: {
          dataPointSelection: this.dataPointSelection,
          dataPointMouseEnter: function (event: any) {
            event.target.style.cursor = "pointer";
          }
        },
        toolbar: {
          show: true,
          tools: {
            download: '<i class="fa fa-download" aria-hidden="true"></i>'
          },
          export: {
            csv: {
              filename: `dashboard-${dayjs().format("DD-MM-YYYY")}`,
              columnDelimiter: ',',
              headerCategory: 'Medida do Pneu',
              headerValue: 'value'
            },
            svg: {
              filename: `dashboard-${dayjs().format("DD-MM-YYYY")}`,
            },
            png: {
              filename: `dashboard-${dayjs().format("DD-MM-YYYY")}`,
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        title: {
          text: "Quantidades"
        }
      },
      xaxis: {
        type: 'category',
        group: {
          style: {
            fontSize: '14px',
            fontWeight: 700
          },
          groups: []
        },
        plotOptions: {
          bar: {
            columnWidth: '30%',
            dataLabels: {
              total: {
                enabled: true,
              }
            }
          },
        }
      },
      colors: [],
      tooltip: {
        x: {
          formatter: function (val: any) {
            return val
          }
        }
      },
    },
  }

  findWorkshops = async () => {
    await axios.get('api/v1/workshops')
      .then((res: any) => {
        this.setState({
          workshops: res.data.data
        })
      })
      .catch((e) => {
        console.log('error', e.message)
      })
  }

  findCities = async () => {
    await axios.get('api/v1/reports/cities')
      .then((res: any) => {
        this.setState({
          cities: res.data.data
        })
      })
      .catch((e) => {
        this.setState({
          cities: []
        })
        console.log('error', e.message)
      })
  }

  public locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
  };

  // handleWorkshops = (values: any) => {
  //   const selectedWorkshops: any = this.state.workshops.filter((workshop: any) => values.includes(workshop.value))
  //   this.setState({ selectedWorkshops: selectedWorkshops })
  // }

  handleWorkshops = (value: any) => {
    if (value.includes('all')) {
      const allWorkshops = this.state.workshops.reduce((acc: any, workshop: any) => {
        if (workshop.children) {
          acc.push(...workshop.children.map((child: any) => child.value));
        } else {
          acc.push(workshop.value);
        }
        return acc;
      }, []);
      this.setState({ selectedWorkshops: allWorkshops });
    } else {
      this.setState({ selectedWorkshops: value });
    }
  }

  handleCities = (values: any) => {
    const selectedCities: any = this.state.cities.filter((city: any) => values.includes(city.value))
    this.setState({ selectedCities: selectedCities })
  }

  generateReport = async () => {
    this.setState({ loading: true })
    if (
      this.state.selectedWorkshops.length === 0 &&
      this.state.selectedCities.length === 0 &&
      this.state.dateIni === null &&
      this.state.dateEnd === null
    ) {
      Notification.warning({
        title: "Aviso!",
        description: "Por favor, assegure-se de selecionar ao menos um filtro!",
        duration: 3500,
      })
      this.setState({ loading: false })
    } else {
      const selectedCities = this.state.selectedCities.map((city: any) => city.value)
      await axios.post('api/v1/dashboard', { workshops: this.state.selectedWorkshops, cities: selectedCities, dateIni: this.state.dateIni, dateEnd: this.state.dateEnd })
        .then((res: any) => {
          console.log(res.data)
          if (res.data.data.length > 0 && res.data.values.length > 0) {
            const dataLegend = res.data.data.map((item: any) => {
              return {
                color: '#' + Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0'),
                value: item.x
              }
            })
            // const uniqueColors = _.uniqBy(dataLegend, 'color');

            const updateSeries = {
              name: "Quantidade",
              data: res.data.data.map((item: any) => {
                const colorObj = dataLegend.find((data: any) => data.value === item.x);
                const color = colorObj ? colorObj.color : '';
                return {
                  x: item.x,
                  y: item.y,
                  fillColor: color,
                };
              }),
            };

            const updatedOptions = {
              ...this.state.options,
              dataLabels: {
                enabled: false
              },
              xaxis: {
                ...this.state.options.xaxis,
                labels: {
                  ...this.state.options.xaxis.labels,
                  style: {
                    fontSize: '0px',
                  }
                },
                group: {
                  ...this.state.options.xaxis.group,
                  style: {
                    fontSize: '8px',
                    fontWeight: 700,
                    wrap: 'break-word',
                  },
                  groups: res.data.values,
                },
              },
              plotOptions: {
                bar: {
                  columnWidth: '30%',
                  dataLabels: {
                    total: {
                      enabled: true,
                    }
                  }
                },
              }
            };
            this.setState({
              data: res.data.data,
              groups: res.data.values,
              series: [updateSeries],
              options: updatedOptions,
              dataLegend: dataLegend
            })
          } else {
            Notification.warning({
              title: "Aviso!",
              description: "Nenhum registro com esses filtros",
              duration: 3500,
            })
          }
        })
        .catch((e) => console.log('error', e.message))
        .finally(() => this.setState({ loading: false }))
    }
    this.setState({ loading: false })
  }
  chartRef: any;

  getTiremesurementId = async (description: string) => {
    await axios.get(`api/v1/tire_measurements/list/?page=1&perPage=10&tire_measurement_description=${description}&tire_measurement_description_op=like`)
      .then((res) => {
        this.setState({
          tireMeasurementId: res.data.items[0].tire_measurement_id
        })
        this.setState(
          {
            filterValues: {
              stock_entrie_created_at_init: {
                "field": "stock_entrie_created_at",
                // "op": "gte",
                "op": "",
                "value": ""
              },
              stock_entrie_created_end: {
                "field": "stock_entrie_created_at",
                // "op": "lte",
                "op": "",
                "value": ""
              },
              stock_entrie_fk_city_id: {
                "field": "stock_entrie_fk_city_id",
                // "op": "andWhere",
                "op": "",
                "value": ''
              },
              stock_entrie_user_fk_user_id: {
                "field": "stock_entrie_user_fk_user_id",
                // "op": "andWhere",
                "op": "",
                "value": ''
              },
              stock_entrie_tire_measurement_fk_tire_measurement_id: {
                "field": "stock_entrie_tire_measurement_fk_tire_measurement_id",
                "op": "andWhere",
                // "op": "",
                "value": res.data.items[0].tire_measurement_id
              },
              service_order_plate: {
                "field": "service_order_plate",
                "op": "",
                "value": ''
              },
              service_order_purchase_order_number: {
                "field": "service_order_purchase_order_number",
                "op": "",
                "value": ''
              },
              stock_entrie_release_type: {
                "field": "stock_entrie_release_type",
                // "op": "andWhere",
                "op": "",
                "value": ''
              }
            },
            generateReport: true
          },
        )
      })
      .catch((e) => console.log("erro", e))
  }

  generateReportMovementStock = async () => {
    await axiosReport
      .post(`/api/v1/reports/generate`, { params: Object.values(this.state.filterValues), crud: "stock_entries", type: "report_movement_stock", showFieldsReport: this.state.showFieldsReport })
      .then((res) => {
        // this.setState({ tireMeasurementId: null })  
        Notification.warning({
          title: 'Relatório',
          description: 'Gerando relatório...',
          duration: 13000
        });
        setTimeout(() => {
          window.open(`${window.location.protocol}//` + window.location.host.split('25565').join("25567") + res.data.outputUrl)
          Notification.success({
            title: 'Relatório',
            description: "Relatório Gerado com sucesso!"
          })
        }, 13000);
      })
      .catch((e) => {
        Notification.error({
          title: 'Erro!',
          description: e.response.data.message
        })
      })
      .finally(() => {
        this.setState({ generateReport: false })
      })
  }

  // componentDidUpdate(prevState: any) {
  //   if (this.state.tireMeasurementId !== null || prevState.tireMeasurementId !== undefined) {
  //     console.log("this.state.tireMeasurementId", this.state.tireMeasurementId)  
  //     console.log("prevState.tireMeasurementId", prevState.tireMeasurementId)
  //     if (this.state.tireMeasurementId !== prevState.tireMeasurementId) {
  //       console.log("mudou o valor")
  //       this.generateReportMovementStock();
  //     }  
  //   }  
  // }

  componentDidUpdate(prevState: any) {
    if (this.state.generateReport === true) {
      this.generateReportMovementStock();
    }
  }

  componentDidMount() {
    this.findWorkshops()
    this.findCities()
  }

  widthDashboard = {
    width: this.state.widthDashboard
  }

  render() {
    return (
      <div>
        {/* <CheckPicker placeholder="Selecione a Oficína" data={this.state.workshops} style={{ width: 280, marginRight: "2%" }} onChange={this.handleWorkshops} /> */}
        <div style={{ display: "flex" }}>
          <div style={{ width: "40%", marginRight: "1.5%" }}>
            <label>Nome Fantasia (Oficina)</label>
            <CheckTreePickerAllDashboard
              preventOverflow={true}
              placement="bottom"
              value={this.state.selectedWorkshops}
              onChange={this.handleWorkshops}
              // defaultValue={this.state.selectedWorkshops}
              // onChange={this.onSelect}
              // cascade={false}
              api={"api/v1/workshops"}
              defaultExpandAll
            // data={this.state.workshops}
            />
          </div>
          <div style={{ width: '25%', marginRight: "1.5%" }}>
            <label>Cidade</label>
            <CheckPicker
              style={{ width: "100%" }}
              placeholder="Selecione..."
              data={this.state.cities}
              onChange={this.handleCities}
            />
          </div>
          <div style={{ width: "15.2%", marginRight: "1.5%" }}>
            <label>Data Inicial</label>
            <DatePicker
              defaultValue={this.state.dateIni}
              // style={{ width: '100%' }}
              locale={this.locale}
              format="DD/MM/YYYY"
              onChange={(date) => {
                this.setState({ dateIni: dayjs(date).format('YYYY-MM-DD HH:mm:ss.sss') })
              }}
              placeholder="DD/MM/AAAA"
              placement={"bottom"}
            />
          </div>
          <div style={{ width: "15.2%" }}>
            <label>Data Final</label>
            <DatePicker
              defaultValue={this.state.dateEnd}
              // style={{ width: '100%' }}
              locale={this.locale}
              format="DD/MM/YYYY"
              onChange={(date) => {
                this.setState({ dateEnd: dayjs(date).format('YYYY-MM-DD HH:mm:ss.sss') })
              }}
              placeholder="DD/MM/AAAA"
              placement={"bottom"}
            />
          </div>
        </div>
        <Button
          style={{ marginTop: "1%", width: "10%" }}
          onClick={this.generateReport}
          color="green"
          loading={this.state.loading}>Buscar</Button>
        <div style={{ marginTop: "2%" }}>
          <ReactApexChart
            ref={(chart) => { this.chartRef = chart }}
            options={{
              ...this.state.options,
              yaxis: {
                ...this.state.options.yaxis,
                labels: {
                  ...this.state.options.yaxis.labels,
                  formatter: function (value) {
                    return Math.round(value);
                  }
                }
              }
            }}
            series={this.state.series}
            type="bar"
            height={400}
            width={"100%"}
          />
          {/* {this.state.series ? (
          ) : (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Loader size={"lg"}/>
            </div>
          )} */}
        </div>
        <div style={{ margin: "20px 0" }}>
          <h5>Legenda de Medidas:</h5>
          {this.state.dataLegend.map((data: any, index: number) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <div style={{ backgroundColor: `${data.color}`, width: 20, height: 10, marginRight: 10 }}></div>
              <p style={{ padding: "5px 0" }}>{data.value}</p>
            </div>
          ))}
        </div>
      </div>
    )
  }
}