import React from "react";
import { Button, Modal as RModal } from "rsuite";

export class ConfirmModal extends React.Component<any, {}> {

    public state = {
        isOpen: this.props.show
    }

    render() {
        return (
            <RModal show={this.props.show && this.state.isOpen} size={"sm"} className="centered" on>
                <RModal.Header closeButton={false}>
                    <RModal.Title style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <i className="fas fa-fw mr-2 o-4 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                        </div>
                        <div>Atenção!</div>
                        <div>
                            <i className="fas fa-fw mr-2 o-4 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                        </div>
                    </RModal.Title>
                </RModal.Header>
                <RModal.Body style={{textAlign:'center'}}>
                    {this.props.children}
                </RModal.Body>
                <RModal.Footer>
                    <Button appearance="primary" onClick={() => this.setState({isOpen: false})}>Confirmar</Button>
                </RModal.Footer>
            </RModal>
        );
    }
}
