import React from "react";
import ProductStockModal from "../../utilities/ProductStockModal";

export class DeveloperProductEvents {
    public value: number | null = null;

    public getSuffix = () => {
        return <ProductStockModal value={this.value} />;
    };
}
