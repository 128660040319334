import { Event } from "../Event";

export class UserEvents extends Event {
  public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
    if (this.globalProps.values.user_fk_role_id == 2) { // tipo oficina
      this.setField("user_fantasy_name", { hidden: false });
      this.setField("user_contacts", { hidden: false });
      this.setField("user_contact_name_1", { hidden: false });
      this.setField("user_email_name_1", { hidden: false });
      this.setField("user_contact_name_2", { hidden: false });
      this.setField("user_email_name_2", { hidden: false });
      this.setField("user_obs", { hidden: false });
      this.setField("user_files", { hidden: false });
      this.setField("user_color", { hidden: false });
    }
  }
}