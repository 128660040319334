import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";
import { Notification } from "rsuite";

export class ItemFkProductIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        console.log('globalProps::', this?.globalProps?.values)
        this.setState('loadings', [
            'item_cod_item', 'item_description', 'item_sale', 'item_unit'
        ])
        // console.log('global:::', this.globalProps?.parentValues?.order_table_price_fk_price_id)
        if (!this.globalProps?.parentValues?.order_table_price_fk_price_id) {
            Notification.warning({
                title:"Atenção !",
                description: "VOCÊ ESTÁ SELECIONANDO UM ITEM SEM ESCOLHER UMA TABELA DE PREÇO!"
            })
            return
        }
        axios.get("/api/v1/products/" + value + "?selectTabPreco=" + this.globalProps?.parentValues?.order_table_price_fk_price_id).then((res) => {
            let item = res?.data?.item
            if (res?.data?.item?.selectTabPreco) {
                setField("item_unitary_value", item?.selectTabPreco?.mbm_price_seller_price);
            } else {
                setField("item_unitary_value", item?.product_sale_price);
                Notification.info({
                    title:"Atenção !",
                    description: `Item não encontrado na Tabela de Preço!`
                })
            }
            setField("item_cod_item", item?.product_cod_item);
            setField("item_description", item?.product_name);

            setField("item_unit", item?.product_unit);

        }).finally(() => {
            this.setState('loadings', [])
        })
    }
}
