import { Event } from "../Event";
import axios from "../../utilities/axios";


export class PersonCnpjCpfEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, setFieldValue: any) => {
        // alert(value);
        // setField('person_is_active',false);
        // setState('loadings',[
        //     'person_is_active'
        // ])
        // setField('person_name','abas')
        // return;

        //limpa dados do endereço quando muda o cpf/cnpj
        setField("person_district", "")
        setField("person_zip_code", "")
        setField("person_street", "")
        setField("person_number", "")
        
        if (value && value.length > 11) {
            
            setState("loadings", [
                "person_name",
                "person_fantasy",
                "person_fk_street_type_id",
                "person_street",
                "person_number",
                "person_district",
                "person_fk_city_id",
                "person_fk_state_id",
                "person_fk_country_id",
                "person_complement",
                "person_zip_code",
                "person_principal_phone",
                "person_site",
                "person_principal_mail",
                "person_observation",
                'person_cnaes',
            ]);
            axios
                .get("/api/v1/consulta-cnpj/" + value,{params: {
                    table: 'people',
                    column: 'person_id',
                    id: this.globalState.formValues?.person_id
                }})
                .then(function (res) {
                    var { data } = res.data;
                    
                    if (data.status === "ERROR") {
                        setState("errors", {
                            person_cnpj_cpf: res.data.error ?? "CNPJ Inválido",
                        });
                        setState("loadings", []);
                        return;
                    }
                    if (data.status !== 'ERROR') {
                        // ////console.log(typeof(data.atividade_principal[0].code))
                        // var newNumber = data.atividade_principal[0].code.replace(/[^0-9]/g, '')
                        // ////console.log(typeof(newNumber))

                        // axios.get("/api/v1/cnaes/select", {
                        //     params: {
                        //         search: newNumber
                        //     }
                        // }).then((res) => {
                        //     ////console.log(res.data)
                        //     if(res) {
                        //         //setField("person_cnaes", true)
                        //     }
                        // })

                        var stateID: any = undefined;
                        setField("person_name", data.nome);
                        
                        if (data.fantasia.length) {
                            setField("person_fantasy", data.fantasia.length ? data.fantasia : data.nome);
                        } else {
                            setField("person_fantasy","");
                        }
                        axios
                            .get("/api/v1/states/select", {
                                params: {
                                    state: data.uf,
                                },
                            })
                            .then((res) => {
                                var { items } = res.data;
                                const { label, value } = items[0] ?? undefined;
                                stateID = value;
                                if (label && value) {
                                    setField("person_fk_state_id", { label, value: value.toString() });
                                }
                            })
                            .catch((e) => {})
                            .finally(() => {
                                axios
                                    .get("/api/v1/cities/select", {
                                        params: {
                                            search: data.municipio,
                                            state: stateID,
                                        },
                                    })
                                    .then((res) => {
                                        var { items } = res.data;
                                        var { label, value } = items[0] ?? undefined;
                                        if (label && value) {
                                            setField("person_fk_city_id", { label, value: value.toString() });
                                            setTimeout(() => {
                                                axios
                                                    .get("/api/v1/matching-region/" + value.toString())
                                                    .then((res) => {
                                                        if (res && res.data && res.data.item && res.data.item.region_name) {
                                                            setField("person_fk_region_id", { label: res.data.item.region_name, value: res.data.item.region_id.toString() });
                                                            setField("person_fk_company_id", {
                                                                label: res.data.item.company.company_fantasy,
                                                                value: res.data.item.company.company_id.toString(),
                                                            });
                                                        }
                                                    })
                                                    .catch((e) => {});
                                            }, 1500);
                                        }
                                    })
                                    .catch((e) => {})
                                    .finally(() => {
                                        if (data.atividade_principal[0].code) {
                                            const codigoCnae = data.atividade_principal[0].code.split("-").join("").split(".").join("");
                                            axios
                                                .get("/api/v1/cnaes/select", {
                                                    params: {
                                                        search: codigoCnae,
                                                    },
                                                })
                                                .then((res) => {
                                                    if (res?.data?.items) {
                                                        const items = res?.data?.items;
                                                        const first = items[0];
                                                        axios.get("/api/v1/cnaes/" + first.value).then((res) => {
                                                            if (res?.data?.item) {
                                                                setField("person_cnaes", [
                                                                    {
                                                                        person_cnae_is_principal: true,
                                                                        cnae: res.data.item,
                                                                        person_cnae_fk_cnae_id: first.value,
                                                                    },
                                                                ]);
                                                            }
                                                        }).finally(() => {
                                                            setState("loadings", []);
                                                        });
                                                    } else {
                                                        setState("loadings", []);
                                                    }
                                                }).catch((e) => {
                                                    setState("loadings", []);
                                                });
                                        } else {
                                            setState("loadings", []);
                                        }
                                        
                                    });
                                // setState("loadings", []);
                            });
                        if (data.logradouro.includes("AV") && data.logradouro[0].toLocaleUpperCase() !== "R") {
                            setField("person_fk_street_type_id", { label: "AVENIDA", value: 2 });
                        } else {
                            setField("person_fk_street_type_id", { label: "RUA", value: 1 });
                        }
                        setField("person_zip_code", data.cep.split(".").join("").split("-").join(""));
                        setField("person_street", data.logradouro);
                        // if (data.numero !== "S/N" && data.numero !== "SN") {
                            setField("person_number", data.numero);
                        // } else {
                        //     setField("person_number", undefined);
                        // }
                        setField("person_district", data.bairro);
                        setField("person_complement", data.complemento);
                        setField("person_principal_phone", data.telefone.split(" ").join(""));
                        setField("person_principal_mail", data.email);
                        // setField("person_site", data.);
                    }
                })
                .catch((e) => {
                    setState("loadings", []);
                })
                .finally(() => {
                    // setState("loadings", []);
                });
        }
    };
}
