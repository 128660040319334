import React from "react";
import { Button, Table, DatePicker, SelectPicker, Alert, Progress, Notification, Input } from "rsuite";
import axios from "../../utilities/axios";
import _, { filter } from "lodash";
import TablePagination from "rsuite/lib/Table/TablePagination";
import dayjs from "dayjs";
import { CheckPicker } from 'rsuite';
import axiosReport from "../../utilities/axiosReport";

const { Column, HeaderCell, Cell } = Table;

export class ReportServiceOrder extends React.Component<any, {}> {
  public state: any = {
    dateIni: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
    endDate: dayjs().format('MM/DD/YYYY'),
    plate: "",
    numberRequestOrSs: "",
    selectedValue: 1,
    showFieldsReport: [
      "service_order_mechanics_fk_user_id",
      "service_order_mechanics_cnpj",
      "service_order_plate",
      "service_order_state_fk_situation_id",
      "service_order_purchase_order_number",
      "service_order_state_fk_situation_id"
    ],
    filterValues: {
      service_order_created_at_init: {
        "field": "service_order_created_at",
        "op": "gte",
        "value": dayjs().subtract(30, 'days').format('YYYY-MM-DD 00:00:00.000')
      },
      service_order_created_at_end: {
        "field": "service_order_created_at",
        "op": "lte",
        "value": dayjs().format('YYYY-MM-DD 23:59:59.999')
      },
      // service_order_mechanics_cnpj: {
      //   "field": "service_order_mechanics_cnpj",
      //   // "op": "andWhere",
      //   "op": "",
      //   "value": ''
      // },
      service_order_mechanics_fk_user_id: {
        "field": "service_order_mechanics_fk_user_id",
        "op": "",
        "value": ''
      },
      service_order_plate: {
        "field": "service_order_plate",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      service_order_purchase_order_number: {
        "field": "service_order_purchase_order_number",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
    },
  }

  public locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
  };

  onChange = (alias: any, field: any, op: any, value: any) => {
    this.setState({ filterValues: { ...this.state.filterValues, [alias]: { field: field, op: op, value: value } } })
  };

  handleReport = async () => {
    this.setState({ loading: true })
    await axiosReport
      .post(`/api/v1/reports/generate`, { params: Object.values(this.state.filterValues), crud: "service_orders", type: "report_service", showFieldsReport: this.state.showFieldsReport, paramsSelect: this.state.selectedValue })
      .then((res) => {
        // console.log('url:::', 'https://' + window.location.host.split('25565').join('25567') + res.data.outputUrl)
        setTimeout(() => {
          // window.open('https://' + window.location.host.split('25565').join('25567') + res.data.outputUrl)
          window.open(`${window.location.protocol}//` + window.location.host.split('25565').join("25567") + res.data.outputUrl)
        }, 13000);
      })
      .catch((e) => {
        Notification.error({
          title: 'Erro!',
          description: e.response.data.message
        })
        this.setState({ loading: false });
      })
      .finally(() => {
        // this.setState({ loading: false })
        setTimeout(() => { this.setState({ loading: false }) }, 13000)
      })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // if (prevState.plate !== this.state.plate) {
    //   this.state.filterValues['service_order_plate'].value = this.state.plate
    //   this.state.filterValues['service_order_plate'].op = "andWhere"
    // }
    if (prevState.numberRequestOrSs !== this.state.numberRequestOrSs) {
      this.state.filterValues['service_order_purchase_order_number'].value = this.state.numberRequestOrSs
      this.state.filterValues['service_order_purchase_order_number'].op = "andWhere"
    }
    // if (prevState.cnpj !== this.state.cnpj) {
    //   this.state.filterValues['service_order_mechanics_cnpj'].value = this.state.cnpj
    //   this.state.filterValues['service_order_mechanics_cnpj'].op = "andWhere"
    // }
  }

  render() {
    const { workshops } = this.props
    return (
      <>
        <div style={{ display: 'flex', flexDirection: "column", flexWrap: 'wrap', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '24%', marginBottom: 16, marginRight: "1%" }}>
              <div>Data Inicial</div>
              <DatePicker
                defaultValue={this.state.dateIni}
                style={{ width: '100%' }}
                locale={this.locale}
                format="DD/MM/YYYY"
                onChange={(date: any) => this.onChange("service_order_created_at_init", "service_order_created_at", "gte", dayjs(date).format('YYYY-MM-DD 00:00:00.000'))}
                placeholder="DD/MM/AAAA"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '24%', marginBottom: 16, marginRight: "1%" }}>
              <div>Data Final</div>
              <DatePicker
                defaultValue={this.state.endDate}
                style={{ width: '100%' }}
                locale={this.locale}
                format="DD/MM/YYYY"
                onChange={(date: any) => this.onChange("service_order_created_at_end", "service_order_created_at", "lte", dayjs(date).format('YYYY-MM-DD 23:59:59.999'))}
                placeholder="DD/MM/AAAA"
              />
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginBottom: 16 }}>
              <div>CNPJ da Oficína</div>
              <Input
                value={this.state.cnpj}
                onChange={(event: any) => {
                  this.setState({ cnpj: event })
                }}
              />
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginBottom: 16 }}>
              <div>Nome da Oficina</div>
              <CheckPicker
                data={workshops}
                placeholder={"Selecione..."}
                onChange={(event: any) => {
                  this.onChange("service_order_mechanics_fk_user_id", "service_order_mechanics_fk_user_id", "inlist", event)
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '24%', marginBottom: 16, marginRight: "1%" }}>
              <div>Placa do Veículo</div>
              <Input
                // value={this.state.plate}
                onChange={(event: any) => {
                  if (event) {
                    this.onChange("service_order_plate", "service_order_plate", "andWhere", event)
                  } else {
                    this.onChange("service_order_plate", "service_order_plate", "", event)
                  }
                }}
                // onChange={(event: any) => {
                //   this.setState({ plate: event })
                // }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '24%', marginBottom: 16, marginRight: "1%" }}>
              <div>NRO. Pedido ou SS</div>
              <Input
                value={this.state.numberRequestOrSs}
                onChange={(event: any) => {
                  this.setState({ numberRequestOrSs: event })
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginBottom: 16 }}>
              <div>Selecione</div>
              <SelectPicker
                data={[{ value: 1, label: 'ULTIMA OBSERVAÇÃO' }, { value: 2, label: 'TODAS OBSERVAÇÕES' }]}
                value={this.state.selectedValue}
                onChange={(event) => {
                  this.setState({ selectedValue: event })
                }}
                placeholder={"Selecione..."}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '16%', marginTop: 16 }}>
              <Button
                color="green"
                loading={this.state.loading}
                onClick={(e) => this.handleReport()}
              >Gerar Relatório</Button>
            </div>
          </div>
        </div>
      </>
    )
  }
}