import classNames from "classnames";
import React from "react";

export class Equalizer extends React.Component<any, {}> {
    public state = {
        height: 0,
    };
    public elRef: any;

    constructor(props: any) {
        super(props);
        this.elRef = React.createRef();
    }

    componentDidMount() {
        this.setState({height: this.elRef.current.offsetWidth})
    }

    componentDidUpdate(props: any,state:any) {
        // //
        if(this.elRef.current.offsetWidth !== this.state.height) {
            this.setState({height: this.elRef.current.offsetWidth})
            // return true;
        }
        // return false;
    }

    render() {
        return (
            <div className={classNames('equalizer',this.props.className)} ref={this.elRef} style={{ height: this.state.height }}>
                {this.props.children}
            </div>
        );
    }
}
