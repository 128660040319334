import axios from "../../utilities/axios";
import { Event } from "../Event";

export class PersonFkMacroRegionIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if(value){
            this.setState('loadings',[
                'person_microregion'
            ])
            await axios
            .get(`/api/v1/people/regions/${value}`)
            .then((res: any) => {
                this.setFieldValue('person_microregion', res.data.data)
            })
            .finally(() => {
                this.setState("loadings",[])
            })
        }
    }
}