import { Event } from "../Event"

export class StockEntrieUserFkUserIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (this.globalProps.match.url.includes('/add')) {
            // console.log("fieldssss", this.globalProps.forms);
            if (this.globalProps.values.stock_entrie_user_fk_user_id) {
                this.setField("stock_entrie_tire_measurement_fk_tire_measurement_id", { readonly: false });

                this.setField("stock_entrie_tire_measurement_fk_tire_measurement_id", { api: { ...this.globalProps.form.stock_entrie_tire_measurement_fk_tire_measurement_id.api, url: `/api/v1/picker-consigned/${this.globalProps.values.stock_entrie_user_fk_user_id}?selectcomponent=true` } });
            }
        }
    };
}