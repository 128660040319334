import React from "react";

export class LoginAnimationSvg extends React.Component<any, {}> {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio={"xMidYMid"} viewBox="0 0 1920 927">
                <g transform="translate(960,463.5) scale(1,1) translate(-960,-463.5)">
                    <linearGradient id="lg-0.6084658314145286" x1="0" x2="1" y1="0" y2="0">
                        <stop stopColor="#041f4a" offset="0"></stop>
                        <stop stopColor="#041f4a" offset="1"></stop>
                    </linearGradient>
                    <path d="" fill="url(#lg-0.6084658314145286)" opacity="0.4">
                        <animate
                            attributeName="d"
                            dur="33.333333333333336s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="0s"
                            values="M0 0L 0 778.9351026385217Q 192 668.4080022408335  384 636.0518611055326T 768 465.4585694627662T 1152 354.99655930939525T 1536 225.56788381458216T 1920 104.11509663196546L 1920 0 Z;M0 0L 0 857.6088340208685Q 192 796.284556234644  384 770.203583341513T 768 507.11431247067407T 1152 337.4405681779045T 1536 274.266258638179T 1920 63.84455823535086L 1920 0 Z;M0 0L 0 831.0562020892373Q 192 662.9092608993507  384 639.7318350184548T 768 604.380624529369T 1152 520.1742111746785T 1536 377.83285804401305T 1920 177.34871127078497L 1920 0 Z;M0 0L 0 778.9351026385217Q 192 668.4080022408335  384 636.0518611055326T 768 465.4585694627662T 1152 354.99655930939525T 1536 225.56788381458216T 1920 104.11509663196546L 1920 0 Z"
                        ></animate>
                    </path>
                    <path d="" fill="url(#lg-0.6084658314145286)" opacity="0.4">
                        <animate
                            attributeName="d"
                            dur="33.333333333333336s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="-6.666666666666667s"
                            values="M0 0L 0 909.9274686005726Q 192 675.7851047388551  384 652.6221414073062T 768 524.1635004563262T 1152 430.39510465437354T 1536 332.5966947343754T 1920 197.20124124213817L 1920 0 Z;M0 0L 0 875.5793817506259Q 192 741.6354087905738  384 723.9708185618491T 768 610.6306089228731T 1152 408.58637747930146T 1536 305.57079681611174T 1920 267.98159070666827L 1920 0 Z;M0 0L 0 788.693828649118Q 192 754.4102430563412  384 722.1671052801663T 768 555.9869075742156T 1152 331.0285565838632T 1536 200.0988315258279T 1920 63.847570162411785L 1920 0 Z;M0 0L 0 909.9274686005726Q 192 675.7851047388551  384 652.6221414073062T 768 524.1635004563262T 1152 430.39510465437354T 1536 332.5966947343754T 1920 197.20124124213817L 1920 0 Z"
                        ></animate>
                    </path>
                    <path d="" fill="url(#lg-0.6084658314145286)" opacity="0.4">
                        <animate
                            attributeName="d"
                            dur="33.333333333333336s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="-13.333333333333334s"
                            values="M0 0L 0 871.5189469127689Q 192 735.0014810094732  384 697.7268237047549T 768 508.8237406336725T 1152 443.98847020653807T 1536 341.1333898842846T 1920 192.2922585019321L 1920 0 Z;M0 0L 0 789.2811654161386Q 192 829.2640628670067  384 783.2678086313499T 768 613.8481231364191T 1152 492.6685485807284T 1536 306.1665313424694T 1920 222.157345898872L 1920 0 Z;M0 0L 0 871.3857629079066Q 192 780.3181829289612  384 763.8043457399706T 768 617.2795169466725T 1152 421.2787344747908T 1536 274.17290343644777T 1920 155.39789768600338L 1920 0 Z;M0 0L 0 871.5189469127689Q 192 735.0014810094732  384 697.7268237047549T 768 508.8237406336725T 1152 443.98847020653807T 1536 341.1333898842846T 1920 192.2922585019321L 1920 0 Z"
                        ></animate>
                    </path>
                    <path d="" fill="url(#lg-0.6084658314145286)" opacity="0.4">
                        <animate
                            attributeName="d"
                            dur="33.333333333333336s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="-20s"
                            values="M0 0L 0 926.3978429725976Q 192 651.9459509011621  384 621.4598666887371T 768 528.7901859819834T 1152 506.90302090541894T 1536 256.5034676776994T 1920 190.61259104549958L 1920 0 Z;M0 0L 0 761.0837367475519Q 192 693.1902955433814  384 663.7978472272712T 768 561.5266362673627T 1152 427.63019800385143T 1536 365.77566354108535T 1920 238.021272413955L 1920 0 Z;M0 0L 0 829.7218994515625Q 192 817.6007752870022  384 789.8808098143215T 768 479.0579351878649T 1152 352.056252506952T 1536 339.47242582906745T 1920 92.7609764820345L 1920 0 Z;M0 0L 0 926.3978429725976Q 192 651.9459509011621  384 621.4598666887371T 768 528.7901859819834T 1152 506.90302090541894T 1536 256.5034676776994T 1920 190.61259104549958L 1920 0 Z"
                        ></animate>
                    </path>
                    <path d="" fill="url(#lg-0.6084658314145286)" opacity="0.4">
                        <animate
                            attributeName="d"
                            dur="33.333333333333336s"
                            repeatCount="indefinite"
                            keyTimes="0;0.333;0.667;1"
                            calcMode="spline"
                            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                            begin="-26.666666666666668s"
                            values="M0 0L 0 778.6013659112798Q 192 666.7844910648096  384 631.2016501486462T 768 548.0307395342336T 1152 332.64348151923326T 1536 300.70159339163297T 1920 119.83253980228812L 1920 0 Z;M0 0L 0 768.9717267353004Q 192 708.5544333529966  384 692.4771722065152T 768 603.2784678105403T 1152 380.1950561233987T 1536 336.3183904176624T 1920 103.01167946308817L 1920 0 Z;M0 0L 0 836.6468167762241Q 192 772.1527755595149  384 744.2525446578938T 768 472.04646388556216T 1152 337.4807391651966T 1536 209.6951854678165T 1920 78.79588107195696L 1920 0 Z;M0 0L 0 778.6013659112798Q 192 666.7844910648096  384 631.2016501486462T 768 548.0307395342336T 1152 332.64348151923326T 1536 300.70159339163297T 1920 119.83253980228812L 1920 0 Z"
                        ></animate>
                    </path>
                </g>
            </svg>
        );
    }
}
