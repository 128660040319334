import React from "react";
import { Table, TableProps } from "rsuite";

interface NecTableProps extends TableProps {
    onRowDoubleClick?: Function;
}

export class NecTable extends React.Component<NecTableProps, {}> {
    public clicked = false;
    public doubleClick = false;
    public timeout: any = null;
    onRowClick = (row: any, index: any) => {
        if (this.clicked === false) {
            this.timeout = setTimeout(() => {
                this.clicked = false;
            }, 200);
            this.clicked = true;
        } else {
            if (this.props.onRowDoubleClick) {
                
                this.props.onRowDoubleClick(row, index);
            }
        }

        if (this.props.onRowClick) {
            this.props.onRowClick(row, index);
        }
    };

    render() {
        return <Table onRowClick={this.onRowClick} {...this.props} />;
    }
}
