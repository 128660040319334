import React from "react";
import { Loader, Notification, Tooltip, Whisper } from "rsuite";
import { ConfirmOverlay } from "../../../components/confirmOverlay/ConfirmOverlay";
import axios from "../../../utilities/axios";

class PsmRegistrationProductsActions extends React.Component<any, {}> {
    public state = {
        rowData: this.props.rowData,
        aprovado: false,
        loading: true,
        showConfirm: false,
    };

    public triggerRef: any = null;

    componentDidMount() {
        this.triggerRef = React.createRef();
    }

    public loaded = false;
    componentDidUpdate(prevProps: any, prevState: any) {
        // console.log("CHECKING",prevProps,prevState,this.props.rowData.psm_equipment_id !== prevProps.rowData.psm_equipment_id);
        if (this.props.rowData.psm_equipment_id !== undefined && this.loaded == false && this.state.loading == true) {
            
            axios
                .get("/api/v1/clock-in/status/" + this.state.rowData.psm_equipment_id)
                .then((response) => {
                    // alert(response.data.aprovado)
                    this.setState({ aprovado: response.data.aprovado });
                    this.loaded = true;
                })
                .catch((error) => {})
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    }

    aprovar = () => {
        this.setState({ loading: true });
        //TODO: chamar api ao em vez disso aqui né porra
        // setTimeout(() => {
        axios
            .get("/api/v1/clock-in/" + this.state.rowData.psm_equipment_id)
            .then((response) => {
                Notification.success({
                    title: "Sucesso!",
                    description: "Item aprovado com sucesso!",
                });
                this.setState({ rowData: { ...this.state.rowData },aprovado: !this.state.aprovado, loading: false });
            })
            .finally(() => {
                this.setState({ loading: false });
            })
            .catch(() => {
                Notification.error({
                    title: "Erro!",
                    description: "Não foi possível aprovar o item!",
                });
                this.setState({ loading: false });
            });
        // }, 2000);
    };

    render() {
        return (
            <>
                {this.state.rowData.psm_equipment_id !== undefined && (
                    <>
                        {/* {this.state.loading == false && this.state.aprovado !== true && (
                            <>
                                <Whisper trigger={"hover"} placement="top" speaker={<Tooltip>Iniciar</Tooltip>}>
                                    <i onClick={this.aprovar} className="fas text-success fa-fw clickable fa-play"></i>
                                </Whisper>
                            </>
                        )}
                        {this.state.loading == false && this.state.aprovado == true && (
                            <>
                                <Whisper trigger={"hover"} placement="top" speaker={<Tooltip>Parar</Tooltip>}>
                                    <i onClick={this.aprovar} className="fas text-danger clickable fa-fw fa-pause"></i>
                                </Whisper>
                            </>
                        )} */}
                        {this.state.loading == true && <Loader size={"sm"} speed="slow" />}
                    </>
                )}
            </>
        );
    }
}

export class PsmEquipmentEvents {
    public width = 120;

    public getActions = (rowData: any) => {
        return (
            <>
                <PsmRegistrationProductsActions rowData={rowData} />
            </>
        );
    };
}
