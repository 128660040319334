import { Event } from "../Event";


export class UserHubValidationEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        // if(value == false){
            // this.setField("user_macro_regions", {
            //     required: false
            // })
        // }
        // if(value == true){
        //     this.setField("user_macro_regions", {
        //         required: true
        //     })
        // }
    }
}