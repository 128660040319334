import axios from "axios";
import { Event } from "../Event";

// const states: any = {
//     AC: "Acre",
//     AL: "Alagoas",
//     AP: "Amapá",
//     AM: "Amazonas",
//     BA: "Bahia",
//     CE: "Ceará",
//     DF: "Distrito Federal",
//     ES: "Espírito Santo",
//     GO: "Goías",
//     MA: "Maranhão",
//     MT: "Mato Grosso",
//     MS: "Mato Grosso do Sul",
//     MG: "Minas Gerais",
//     PA: "Pará",
//     PB: "Paraíba",
//     PR: "Paraná",
//     PE: "Pernambuco",
//     PI: "Piauí",
//     RJ: "Rio de Janeiro",
//     RN: "Rio Grande do Norte",
//     RS: "Rio Grande do Sul",
//     RO: "Rondônia",
//     RR: "Roraíma",
//     SC: "Santa Catarina",
//     SP: "São Paulo",
//     SE: "Sergipe",
//     TO: "Tocantins",
// };
export class CompanyZipCodeEvents extends Event {
    //init onFocusOut
    public onBlur = (value: any, setField: any, setState: any) => {
        // return;
        if (value) {
            setState("loadings", ["company_district", "company_street", "company_FK_STATE_ID", "company_FK_CITY_ID"]);
            axios
                .get("https://viacep.com.br/ws/" + value + "/json/")
                .then((res) => {
                    if (res && res.data) {
                        // var uf: string = res.data.uf;
                        // this.setProperties("company_FK_STATE_ID", "options", [{ value: res.data.uf, label: states[uf] }]);
                        // setField("company_FK_STATE_ID", res.data.uf);
                        setField("company_district", res.data.bairro);
                        setField("company_street", res.data.logradouro);
                    }
                })
                .finally(() => {
                    setState("loadings", []);
                });
        }
    };
    //end
}
