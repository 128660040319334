import React from "react";
import { Button, InputGroup, Notification, Tooltip, Whisper, Input } from "rsuite";
import { UserContext } from "../../../components/authProvider/main";
import { AdvancedSearchModal } from "../../../components/formfields/AdvancedSearchModal";
import { MailerModal } from "../../../components/mailer/MailerModal";
import axios from "../../../utilities/axios";
import { ConfirmModal } from "../../../components/confirmModal/ConfirmModal";

class EstimateBrowseFooter extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;

    public state = {
        isOpen: false,
        loading: false,
        modalLoading: false,
        to: [],
        cc: [],
        cco: [],
        files: [],
        isAdvancedModalOpen: false,
        emailSubject: "",
        showSituation: false,
        situacaoModal: "",
        psmId: [],
    };

    enviarOrcamentos = () => {
       //console.log("Olha",this.props.state.checked)
        if (this.props.state.checked.length <= 0) {
            Notification.error({
                title: "Erro!",
                description: "Nenhum orçamento selecionado!",
            });
            return;
        }
        this.setState({ loading: true });
        axios
            .post("/api/utils/email/get-estimates", {
                ids: this.props.state.checked,
            })
            .then((res) => {
                // console.log(res.data)
                const files = [];
                for (var i in res.data.files) {
                    const file = res.data.files[i];
                    files.push({
                        url: file,
                        file: {
                            name: file.split("/").pop(),
                        },
                        status: "success",
                        name: file.split("/").pop(),
                    });
                }
                this.setState({
                    files,
                    emailSubject: "Orçamentos PSM's " + Object.values(res.data.codes).join(", "),
                    cco: this.context.data.user_parameters["mail.default.cco"] === undefined ? ["Sem email padrão"] : this.context.data.user_parameters["mail.default.cco"],
                    cc: this.context.data.user_parameters["mail.default.cc"] === undefined ? ["Sem email padrão"] : this.context?.data?.user_parameters["mail.default.cc"],
                    to: [...Object.values(res.data.contacts), this.context.data.user_parameters["mail.default.from"]  === undefined ? [""] : this.context.data.user_parameters["mail.default.from"]],
                    isOpen: true,
                    psmId: this.props.state.checked,
                });
                // console.log("Testando o TO ::::",this.state.to);
                // console.log("Testando o cc", this.state.cc);
                // console.log("Testando o cco", this.state.cco);
            })
            .catch((e) => {
                if(e.response.status == 406) {
                    Notification.error({
                        title: "Erro!",
                        description: e.response.data.message,
                    });
                } else {
                    console.error(e);
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    afterSend = () => {
        this.setState({ loading: true });
        axios.post('/api/v1/bulk-update/estimate',{
            data: {
                psm_status: 3
            },
            ids: this.props.state.checked
        }).then((res) => {
            Notification.success({
                title: "Sucesso!",
                description: "Orçamentos atualizados com sucesso!",
            })
        }).catch(() => {

        }).finally(() => {
            this.setState({ loading: false });
        })
        // alert("ATUALIZANDO STATUS DAS PSMS " + JSON.stringify(this.props.state.checked));
    };

    onSelectContact = (contactData: any) => {
        //console.log("Teste :::::", contactData)
        this.setState({modalLoading: true})
        /* push contactData.value to state.to */
        axios
            .get("/api/v1/person-contacts/" + contactData.value)
            .then((res) => {
                if (res.data.item.person_contact_mail) {
                    this.setState({
                        to: [...this.state.to, res.data.item.person_contact_mail],
                        isAdvancedModalOpen: false,
                    });
                } else {
                    Notification.error({
                        title: "Erro!",
                        description: "Contato sem email!",
                    });
                }
            })
            .finally(() => {
                this.setState({ isAdvancedModalOpen: false,modalLoading: false });
            });
    };

    trocaStatus = (status : 'avanca' | 'voltar' = 'avanca') => {
        // console.log(this.props.state.checked)
        if (this.props.state.checked.length <= 0) {
            Notification.error({
                title: "Erro!",
                description: "Nenhum orçamento selecionado!",
            });
            return;
        }
        // this.props.onSubmit(() => {
        //     "/api/v1/estimates/status/"+status+"/" + this.props.printId
        // })
        this.setState({ loading: true });
        axios
            .get("/api/v1/estimates/statos/"+status+"/" + JSON.stringify(this.props.state.checked))
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    Notification.success({
                        title: "Secesso!",
                        description: "Orçamentos atualizados com sucesso!",
                    });
                }
            }))   
            .finally(() => {
                this.setState({ loading: false });
            });  
    }

    alteraSituacao = () => {
        this.setState({ showSituation: true })
        axios
            .get("/api/v1/psm/mudandoStatus/" + this.props.printId)
            .then((res => {
                if (res.status === 200 && res.data.status) {
                    this.setState({situacaoModal: res.data.tiposLast});
                    // console.log(res.data.tiposLast);
                    //this.props.history.go(0)
                }
            }))   
            .finally(() => {
                this.setState({ loading: false });
            }); 
    }

    render() {
        return (
            <>
                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showSituation: false,
                        })
                    }}
                    body={<>
                        <Button loading={this.state.loading} color='orange' onClick={() => this.trocaStatus('voltar')}>VOLTAR STATUS</Button>
                        <Button loading={this.state.loading} color='blue' style={{marginLeft:20}} onClick={() => this.trocaStatus('avanca')}>AVANÇAR STATUS</Button>
                    </>}
                    cancelColor={"red"}
                    showConfirm={false}
                    cancelText={"Fechar"}
                    onCloseButton={true}
                    content={""}
                    returnText={"Voltar Situação"}
                    advancedText={"Avançar Situação"}
                    title={"Avança situação de ordem de serviço"}
                    
                    show={this.state.showSituation}
                />
                <Button color={"blue"} loading={this.state.loading} onClick={this.enviarOrcamentos}>
                    <i className="fas fa-paper-plane fa-fw mr-2"></i>Enviar orçamentos
                </Button>
                {/* <Button loading={this.state.loading} onClick={() => this.alteraSituacao()} color={"blue"}  className="ml-4">
                    <i className="fas fa-tasks fa-fw mr-2"></i>
                    Mudar Situação
                </Button> */}
                <Button loading={this.state.loading} color='orange' style={{marginLeft:10}} onClick={() => this.trocaStatus('voltar')}>
                    <i className="fa-solid fa-circle-arrow-left fa-fw mr-2"></i>
                    VOLTAR SITUAÇÃO
                </Button>
                <Button loading={this.state.loading} color='blue' style={{marginLeft:10}} onClick={() => this.trocaStatus('avanca')}>
                    <i className="fa-solid fa-circle-arrow-right fa-fw mr-2"></i>
                    AVANÇAR SITUAÇÃO
                </Button>
                <MailerModal
                    afterSend={this.afterSend}
                    files={this.state.files}
                    emailSubject={this.state.emailSubject}
                    onHide={() => this.setState({ isOpen: false })}
                    cc={this.state.cc}
                    cco={this.state.cco}
                    loading={this.state.modalLoading}
                    to={this.state.to}
                    isOpen={this.state.isOpen}
                    psmId={this.state.psmId}>
                    <Whisper trigger="hover" delayHide={0} placement="top" speaker={<Tooltip>Selecionar contatos do cliente</Tooltip>}>
                        <InputGroup.Button onClick={() => this.setState({ isAdvancedModalOpen: true })} color="blue">
                            <i className="fas fa-fw fa-search"></i>
                        </InputGroup.Button>
                    </Whisper>
                </MailerModal>
                <AdvancedSearchModal onSelect={this.onSelectContact} show={this.state.isAdvancedModalOpen} crud="person_contacts" />
            </>
        );
    }
}

export default class EstimateBrowseEvents {
    renderFooter = (props: any, state: any) => {
        return <EstimateBrowseFooter props={props} state={state} />;
    };
}
