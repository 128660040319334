import axios from "../../utilities/axios";
import { Event } from "../Event";
// import { ConsultaCNPJ } from "./ConsultaCNPJ";

export class CompanyCnpjEvents extends Event {
    //init onFocusOut
    public onBlur = (value: any, setField: any, setState: any) => {
        if (value) {
            setState("loadings", [
                "company_email",
                "company_phone",
                "company_complement",
                "company_district",
                "company_number",
                "company_street",
                "company_fk_street_type_id",
                "company_fk_city_id",
                "company_fk_state_id",
                "company_fantasy",
                "company_abbreviation",
                "company_name",
                "company_zip_code",
            ]);
            this.setState({ loadings: ["company_cnpj"] });
            axios
                .get("/api/v1/exists/companies/company_cnpj", { params: { value,id: this.globalState.formValues.company_id } })
                .then((res) => {
                    // this.setState({
                    //     loadings: [],
                    // });
                    setState("loadings", []);
                    this.setState({
                        errors: {
                            company_cnpj: "Esse CNPJ já existe!",
                        },
                    });
                })
                .catch((e) => {
                    axios
                        .get("/api/v1/consulta-cnpj/" + value)
                        .then((res) => {
                            var { data } = res.data;
                            if (data.status === "ERROR") {
                                setState("loadings", []);
                                return;
                            }
                            if (data) {
                                if (data.fantasia.length) {
                                    setField("company_fantasy", data.fantasia.length ? data.fantasia : data.nome);
                                }
                                var stateId: any = undefined;
                                setField("company_name", data.nome);
                                setField("company_zip_code", data.cep.split(".").join(""));
                                axios
                                    .get("/api/v1/states/select", {
                                        params: {
                                            state: data.uf,
                                        },
                                    })
                                    .then((res) => {
                                        const { items } = res.data;
                                        const { label, value } = items[0] ?? undefined;
                                        stateId = value;
                                        if (label && value) {
                                            setField("company_fk_state_id", { label, value: value.toString() });
                                        }
                                    })
                                    .catch((e) => {})
                                    .finally(() => {
                                        axios
                                            .get("/api/v1/cities/select", {
                                                params: {
                                                    search: data.municipio,
                                                    state: stateId,
                                                },
                                            })
                                            .then((res) => {
                                                var { items } = res.data;
                                                var { label, value } = items[0] ?? undefined;
                                                if (label && value) {
                                                    setTimeout(() => {
                                                        this.setFieldValue("company_fk_city_id", { label, value: value.toString() });
                                                    }, 200);
                                                }
                                            })
                                            .catch((e) => {})
                                            .finally(() => {
                                                setState("loadings", []);
                                            });
                                    });
                                if (data.logradouro.includes("AV") && data.logradouro[0].toLocaleUpperCase() !== "R") {
                                    setField("company_fk_street_type_id", { label: "AVENIDA", value: "2" });
                                } else {
                                    setField("company_fk_street_type_id", { label: "RUA", value: "1" });
                                }
                                setField("company_street", data.logradouro);
                                setField("company_number", data.numero);
                                setField("company_district", data.bairro);
                                setField("company_complement", data.complemento);
                                setField("company_phone", data.telefone.split(" ").join(""));
                                setField("company_email", data.email);
                            }
                        })
                        .catch((e) => {
                            setState("loadings", []);
                        })
                        .finally(() => {});
                })
                .finally(() => {});
        }
    };
    //end

    // public render = () => {
    // return <ConsultaCNPJ />
    // };
}
