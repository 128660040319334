import React from "react";
import { Input, Loader, Notification, Tooltip, Whisper } from "rsuite";
import { ConfirmOverlay } from "../../../components/confirmOverlay/ConfirmOverlay";
import { Event } from "../../../events/Event";

export class OrderCustom extends React.Component<any, {}> {
  columnItemStyle = {
    flex: "1",
    height: "80px",
    border: "1px solid #ccc"
  };

  title = {
    whiteSpace: "nowrap" as const,
    fontSize: "16px",
    borderBottom: "1px solid #ccc",
    padding: "5px"
  };

  render() {
    const columnItemStyle = this.columnItemStyle
    const title = this.title
    let qtdItems = 0
    let total = 0

    for (var i in this?.props?.values?.items) {
      let item = this?.props?.values?.items[i]
      qtdItems = parseInt(qtdItems + item.item_amount)
      total = total + ((item.item_unitary_value || item.products.product_sale_price) * item.item_amount)
    }

    this.props.values.order_cost = total ?? 0
    this.props.values.order_quantity = qtdItems ?? 0
    return (
      <div>
        {/* {JSON.stringify(this.props.values.order_cost)} */}
        <div style={{ display: "flex", textAlign: "center", marginTop: "10px" }}>
          <div style={columnItemStyle}>
            <p style={title}>{'Quantidade de Itens'}</p>
            <h3>{qtdItems}</h3>
          </div>
          <div style={columnItemStyle}>
            <p style={title}>{'Valor Total'}</p>
            <h3>{'R$ '} {total?.toFixed(2)} </h3>
          </div>
          {/* <div style={columnItemStyle}>
            <p style={title}>{'item.label'}</p>
            <h3>{item.suffix}{item.value}{item.preffix}</h3>
          </div> */}
        </div>
      </div>
    );
  };
}