var errorsHandlers: any = {};
const statusHandler: any = {};

var onError = function (name: string, callback = function (err: any) {}) {
    errorsHandlers[name] = callback;
};
var unsubError = function (name: string) {
    delete errorsHandlers[name];
};
var triggerErrorHandlers = function (error: any) {
    for (var i in errorsHandlers) {
        if (typeof errorsHandlers[i] === "function") {
            errorsHandlers[i](error);
        }
    }
};

const onStatusResponse = function (statusCode: string, callback: Function) {
    statusHandler[statusCode] = callback;
};
const callStatusResponse = function (statusCode: string) {
    if (statusHandler[statusCode]) {
        statusHandler[statusCode]();
    }
};

export { onError, unsubError, triggerErrorHandlers, onStatusResponse, callStatusResponse };
