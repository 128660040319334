import _ from "lodash";
import { useEffect, useState } from "react";
import { Whisper, Tooltip, Table } from "rsuite";

const { Cell, } = Table;

const CellColor = ({ rowData, dataKey, valideRow, color, stateObserve, indexKey, ...props }: any) => {
    // States
    const [colors, setColors] = useState({
        colorCell: '#ffff',
        colorRow: '#575757',
        rowText: ''
    })
    const colorColumns = (rowData: any, dataKey: string) => {
        const output = {
            colorCell: '#ffff',
            colorRow: '#575757',
            rowText: ''
        }
        const nameColumn: any = dataKey.split('_validate').shift();
        if (rowData[`${nameColumn}_valid`] === false) {
            output.colorCell = '#E06C75'
            output.colorRow = '#ffff'
            output.rowText = 'Erro!'
        }
        if (rowData[`${nameColumn}_warning`] === false) {
            output.colorCell = '#fdfd96'
            output.colorRow = '#575757'
            output.rowText = 'Aviso'
        }
        if (dataKey === 'id_alter' && rowData['id_valid'] === false) {
            output.colorCell = '#a0d995'
            output.colorRow = '#ffff'
            output.rowText = ''
        }

        return {
            ...output
        }
    }

    const colorNcm = (type: string) => {
        const output = {
            colorCell: '#ffff',
            colorRow: '#575757',
            rowText: ''
        }
        if (rowData.typeNcm == 'selectIsXml' && dataKey === 'NCM_origin') {
            output.colorCell = '#a0d995'
            output.colorRow = '#ffff'
            output.rowText = 'Mantido'
        }
        if (rowData.typeNcm === 'selectIsOrder' && dataKey === 'NCM_validate') {
            output.colorCell = '#a0d995'
            output.colorRow = '#ffff'
            output.rowText = 'Mantido'
        }
        if (rowData.typeNcm === 'cancelNote') {
            output.colorCell = '#E06C75'
            output.colorRow = '#ffff'
            output.rowText = 'Nota Cancelada!'
        }

        if (rowData.typeIdOrder === 'selectAlter' && dataKey === 'id_validate') {
            output.colorCell = '#a0d995'
            output.colorRow = '#ffff'
            output.rowText = 'Mantido'
        }
        if (rowData.typeNcm === 'cancelNote' && dataKey === 'id_validate') {
            output.colorCell = '#E06C75'
            output.colorRow = '#ffff'
            output.rowText = 'Nota Cancelada!'
        }
        return {
            ...output
        }
    }

    useEffect(() => {
        if (['', undefined].includes(stateObserve)) {
            setColors(colorColumns(rowData, dataKey))
        } else {
            setColors(colorNcm(stateObserve))
        }
    }, [stateObserve, indexKey, rowData[dataKey]])

    if (colors.rowText !== '') {
        return (
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{colors.rowText}</Tooltip>}>
                <Cell {...props} style={{ backgroundColor: `${colors.colorCell}`, color: `${colors.colorRow}` }} >
                    {rowData[dataKey]}
                </Cell>
            </Whisper>
        )
    } else {
        return (
            <Cell {...props} style={{ backgroundColor: `${colors.colorCell}`, color: `${colors.colorRow}` }} >
                {rowData[dataKey]}
            </Cell>
        )
    }
}


export { CellColor }