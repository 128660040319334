import React from "react";
import arrayMove from 'array-move';
import * as HOC from "react-sortable-hoc";

const SortableItem = HOC.SortableElement(({ value }: any) => <li className="drag-item" style={{userSelect:'none'}} dangerouslySetInnerHTML={{__html:value.label ?? value.name}}></li>);

const SortableContainer = HOC.SortableContainer(({ children }: any) => {
    return <ul className="drag-list">{children}</ul>;
});

export class SortableArray extends React.Component<any, {}> {
    public state = {
        items: this.props.items ? Object.values(this.props.items) : []
    }

    onSortEnd = ({ oldIndex, newIndex } : any) => {
        this.setState(({ items } : any) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }),() => {
            if(this.props.onChange) {
                this.props.onChange(Object.values(this.state.items).map((item:any) => item.name))
            }
        });
    };

    render() {
        // var items: any[] = [];
        return (
            <SortableContainer  onSortEnd={this.onSortEnd}>
                {this.state.items.map((value : any, index : any) => (
                    <SortableItem key={`item-${value}`} index={index} value={value} />
                ))}
            </SortableContainer>
        );
    }
}
