import classNames from "classnames";
import React from "react";
import { Redirect, withRouter } from "react-router";
import { Button, ControlLabel, FormGroup, Input, InputGroup } from "rsuite";
import InputGroupAddon from "rsuite/lib/InputGroup/InputGroupAddon";
import InputGroupButton from "rsuite/lib/InputGroup/InputGroupButton";
import Logo from "../assets/mind.png";
import { AuthContext } from "../components/authProvider/main";
import { ConfirmModal } from "../components/modal/main";
import { LoginAnimationSvg } from "../svg/LoginAnimationSvg";
import axios from "../utilities/axios";
import qs from "query-string";
import { Helmet } from "react-helmet";

class LoginPage extends React.Component<any, {}> {
    static contextType = AuthContext;
    public state = {
        isPasswordVisible: false,
        isLoading: false,
        userUsername: "",
        userPassword: "",
        errorMessage: null,
    };

    doLogin = () => {
        // //
        this.setState({ isLoading: true, errorMessage: null });
        // this.context.setAuth("abc");
        axios
            .post("/api/v1/auth", {
                user_username: this.state.userUsername,
                user_password: this.state.userPassword,
            })
            .then((res) => {
                if (res && res.data && res.data.token) {
                    this.context.setAuth(res.data.token);
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.status === 409) {
                    // alert(err.response.data.message)
                    this.setState({ errorMessage: err.response.data.message });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        // this.props.history.push('/')
    };

    render() {
        if (this.context.isAuthenticated) {
            return <Redirect to="/dashboard/menus" />;
        }
        var { isAuthDone, failedToConnect } = qs.parse(this.props.location.search);
        // if(isAuthDone){
        //     localStorage.removeItem("currentCompany")
        // }
        return (
            <div className="login-page">
                <Helmet>
                    <title>Disemp - Login</title>
                </Helmet>
                <LoginAnimationSvg />
                <div className="background">
                    <div className="form">
                        <div className="content left">
                            <ConfirmModal show={isAuthDone || failedToConnect}>
                                {/* {JSON.stringify()} */}
                                {isAuthDone ? 'Parece que sua sessão terminou, será necessário fazer o login novamente!' : 'Houve uma falha ao conectar-se com o servidor!'}
                            </ConfirmModal>
                            <form onSubmit={() => this.doLogin()}>
                                <FormGroup className="mb-4">
                                    <ControlLabel>Usuário</ControlLabel>
                                    <InputGroup>
                                        <InputGroupAddon>
                                            <i className="fas fa-user"></i>
                                        </InputGroupAddon>
                                        <Input onPressEnter={this.doLogin} onChange={(v) => this.setState({ userUsername: v })}></Input>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="mb-6">
                                    <ControlLabel>Senha</ControlLabel>
                                    <InputGroup>
                                        <InputGroupAddon>
                                            <i className="fas fa-lock"></i>
                                        </InputGroupAddon>
                                        <Input onPressEnter={this.doLogin} onChange={(v) => this.setState({ userPassword: v })} type={this.state.isPasswordVisible ? "text" : "password"}></Input>
                                        <InputGroupButton onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}>
                                            <i className={classNames("fas fa-fw", "fa-" + (!this.state.isPasswordVisible ? "eye" : "low-vision"))}></i>
                                        </InputGroupButton>
                                    </InputGroup>
                                </FormGroup>
                            </form>
                            <div style={{ position: "relative", width: "100%" }}>
                                <small style={{ position: "absolute", top: -10, color: "red" }}>{this.state.errorMessage}</small>
                                <Button style={{ background: "#041f4a", width: "100%", marginTop: 5 }} color="green" onClick={this.doLogin} loading={this.state.isLoading}>
                                    Entrar
                                </Button>
                            </div>

                            {/* <Message type={"error"}  description={this.state.errorMessage} >
                                
                            </Message> */}
                        </div>
                        <div className="content right">
                            <img alt={"Grupo Disemp - Logo"} src={Logo} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(LoginPage);
