import React from "react";
import { Nav, Navbar } from "rsuite";

// export class ModalTabs extends React.Component<any, {}> {
//     render() {
//         return <>oi</>;
//     }
// }
export const TModal = {
    Tabs: class Tabs extends React.Component<any, {}> {
        public state = {
            activeTab: this.props?.tabs[0]?.key,
        };

        render() {
            var children: any = this.props.children;
            // for(var i in this.props.children) {
            //     var children = this.props.children[i];

            // }

            return (
                <>
                    <Navbar className="col-md-12 pill-tabs modal-tabs">
                        <Navbar.Body>
                            <Nav activeKey={this.state.activeTab}>
                                {this.props.tabs.map((item:any) => {
                                    return <Nav.Item onSelect={() => this.setState({activeTab: item.key})} key={item.key} eventKey={item.key}>{item.label}</Nav.Item>
                                })}
                                {/* <Nav.Item eventKey={"teste"}>teste</Nav.Item>
                                <Nav.Item>teste2</Nav.Item> */}
                            </Nav>
                        </Navbar.Body>
                    </Navbar>
                    {children.filter((item: any) => {
                        return item.props.tab === this.state.activeTab;
                    })}
                </>
            );
        }
    },
    Tab: class Tab extends React.Component<any, {}> {
        render() {
            return <>{this.props.children}</>;
        }
    },
};
