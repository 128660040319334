import React from "react";
import { Button, Table, DatePicker, SelectPicker, Alert, Progress, Notification, Input, Checkbox, CheckTreePicker, InputNumber } from "rsuite";
import axios from "../../utilities/axios";
import _, { filter } from "lodash";
import TablePagination from "rsuite/lib/Table/TablePagination";
import dayjs from "dayjs";
import { CheckPicker } from 'rsuite';
import axiosReport from "../../utilities/axiosReport";

const { Column, HeaderCell, Cell } = Table;
export class ReportMovementStock extends React.Component<any, {}> {
  public state: any = {
    dateIni: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
    endDate: dayjs().format('MM/DD/YYYY'),
    loading: false,
    plate: "",
    osNumber: "",
    selectedWorkshop: null,
    city: null,
    checked: false,
    showFieldsReport: [
      "stock_entrie_fk_city_id",
      "stock_entrie_user_fk_user_id",
      "stock_entrie_tire_measurement_fk_tire_measurement_id",
      "stock_entrie_created_at",
      "stock_entrie_release_type",
      "stock_entrie_quantity",
      "stock_entrie_origin",
      "stock_entrie_service_request_number"
    ],
    filterValues: {
      stock_entrie_created_at_init: {
        "field": "stock_entrie_created_at",
        "op": "gte",
        // "op": "",
        "value": dayjs().subtract(30, 'days').format('YYYY-MM-DD 00:00:00.000')

      },
      stock_entrie_created_end: {
        "field": "stock_entrie_created_at",
        "op": "lte",
        // "op": "",
        "value": dayjs().format('YYYY-MM-DD 23:59:59.999')
      },
      stock_entrie_fk_city_id: {
        "field": "stock_entrie_fk_city_id",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      stock_entrie_user_fk_user_id: {
        "field": "stock_entrie_user_fk_user_id",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      stock_entrie_tire_measurement_fk_tire_measurement_id: {
        "field": "stock_entrie_tire_measurement_fk_tire_measurement_id",
        // "op": "andWhere",
        "op": "",
        "value": ''
      },
      service_order_plate: {
        "field": "service_order_plate",
        "op": "",
        "value": ''
      },
      // service_order_id: {
      //   "field": "service_order_id",
      //   "op": "",
      //   "value": ''
      // },
      stock_entrie_os_fk_service_order_id: {
        "field": "stock_entrie_os_fk_service_order_id",
        "op": "",
        "value": ''
      },
      stock_entrie_service_request_number: {
        "field": "stock_entrie_service_request_number",
        "op": "",
        "value": ''
      },
      stock_entrie_release_type: {
        "field": "stock_entrie_release_type",
        // "op": "andWhere",
        "op": "",
        "value": ''
      }
    },
  }

  public locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
  };

  handleReport = async () => {
    this.setState({ loading: true })
    await axiosReport
      .post(`/api/v1/reports/generate`, { params: Object.values(this.state.filterValues), crud: "stock_entries", type: "report_movement_stock", showFieldsReport: this.state.showFieldsReport, checked: this.state.checked })
      .then((res) => {
        setTimeout(() => {
          // window.open('https://' + window.location.host.split('25565').join("25567") + res.data.outputUrl)
          window.open(`${window.location.protocol}//` + window.location.host.split('25565').join("25567") + res.data.outputUrl)
        }, 13000);
      })
      .catch((e) => {
        Notification.error({
          title: 'Erro!',
          description: e.response.data.message
        })
        this.setState({ loading: false });
      })
      .finally(() => {
        setTimeout(() => { this.setState({ loading: false }) }, 13000)
      })
  }

  onChange = (alias: any, field: any, op = "", value: any) => {
    this.setState({ filterValues: { ...this.state.filterValues, [alias]: { field: field, op: op, value: value } } })
  };

  handleChecked = (event: any) => {
    this.setState({
      checked: !event
    });
  }

  findWorkshopsByCityID = async (city_id: any) => {
    console.log(city_id);
    await axios
      .get(`api/v1/reports/workshopsByCityID/${city_id}`)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({ workshops: res.data.data })
        }
      })
      .catch((e) => console.log(e.message))
  }

  render() {
    const { tireMeasurements, workshops, cities } = this.props
    return (
      <>
        <div style={{ display: 'flex', flexDirection: "column", flexWrap: 'wrap', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>Cidade</div>
              <CheckPicker
                data={cities}
                placeholder={"Selecione..."}
                onClean={() => this.setState({ workshops: workshops })}
                onSelect={(event) => this.findWorkshopsByCityID(event)}
                onChange={(event) => {
                  this.onChange("stock_entrie_fk_city_id", "stock_entrie_fk_city_id", "inlist", event);
                  // this.setState({ city: event })
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '39.8%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>Nome Fantasia (Oficina)</div>
              <CheckPicker
                data={this.state.workshops || workshops}
                placeholder={"Selecione..."}
                onChange={(event: any) => {
                  this.onChange("stock_entrie_user_fk_user_id", "stock_entrie_user_fk_user_id", "inlist", event)
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '15.2%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>Data Inicial</div>
              <DatePicker
                defaultValue={this.state.dateIni}
                // style={{ width: '100%' }}
                locale={this.locale}
                format="DD/MM/YYYY"
                // onChange={(date: any) => this.onChange("stock_entrie_created_at_init", "stock_entrie_created_at", "gte", dayjs(date).format('YYYY-MM-DD HH:mm:ss.sss'))}
                onChange={(date: any) => {
                  this.onChange("stock_entrie_created_at_init", "stock_entrie_created_at", "gte", dayjs(date).format('YYYY-MM-DD 00:00:00.000'))
                }
                }
                placeholder="DD/MM/AAAA"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '15.2%', marginBottom: 16 }}>
              <div>Data Final</div>
              <DatePicker
                defaultValue={this.state.endDate}
                // style={{ width: '100%' }}
                locale={this.locale}
                format="DD/MM/YYYY"
                // onChange={(date: any) => this.onChange("stock_entrie_created_at_end", "stock_entrie_created_at", "lte", dayjs(date).format('YYYY-MM-DD HH:mm:ss.sss'))}
                onChange={(date: any) => this.onChange("stock_entrie_created_at_end", "stock_entrie_created_at", "lte", dayjs(date).format('YYYY-MM-DD 23:59:59.999'))}
                placeholder="DD/MM/AAAA"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '25%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>Medida</div>
              <CheckPicker
                data={tireMeasurements}
                placeholder={"Selecione..."}
                onChange={(event) => {
                  this.onChange("stock_entrie_tire_measurement_fk_tire_measurement_id", "stock_entrie_tire_measurement_fk_tire_measurement_id", "inlist", event)
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '19.4%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>Placa</div>
              <Input
                onChange={(event: any) => {
                  if (event !== '') {
                    event = event.trim()
                    this.onChange("service_order_plate", "service_order_plate", "custom", event)
                  } else {
                    this.onChange("service_order_plate", "service_order_plate", "", "")
                  }
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '19%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>N° OS</div>
              <Input
                // value={this.state.osNumber}
                onChange={(event: any) => {
                  if (event) {
                    this.onChange("stock_entrie_os_fk_service_order_id", "stock_entrie_os_fk_service_order_id", "andWhere", event)
                  } else {
                    this.onChange("stock_entrie_os_fk_service_order_id", "stock_entrie_os_fk_service_order_id", "", event)
                  }
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '15%', marginBottom: 16, marginRight: "1.6%" }}>
              <div>N° SS</div>
              <Input
                // value={this.state.osNumber}
                onChange={(event: any) => {
                  if (event) {
                    this.onChange("stock_entrie_service_request_number", "stock_entrie_service_request_number", "andWhere", event)
                  } else {
                    this.onChange("stock_entrie_service_request_number", "stock_entrie_service_request_number", "", event)
                  }
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '14.8%', marginBottom: 16 }}>
              <div>Tipo de Lançamento</div>
              <CheckPicker
                data={[{ value: "E", label: 'Entrada' }, { value: "S", label: 'Saída' }, { value: "C", label: 'Cancelado' }]}
                placeholder={"Selecione..."}
                onChange={(event) => this.onChange("stock_entrie_release_type", "stock_entrie_release_type", "inlist", event)}
              />
            </div>
            <div style={{ display: "inline-block", width: "100%" }}>
              <Checkbox value={this.state.checked} onChange={(event: any) => this.handleChecked(event)}>Exibir Observações dos Ajustes de Saída</Checkbox>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '16%', marginTop: 16 }}>
              <Button
                color="green"
                loading={this.state.loading}
                onClick={() => this.handleReport()}>Gerar Relatório</Button>
            </div>
          </div>
        </div>
      </>
    )
  }
}