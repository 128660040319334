import classNames from "classnames";
import React from "react";
// import { Input, InputGroup, Tree } from "rsuite";

class DynamicTreeItem extends React.Component<any, {}> {
    public state = {
        isOpen: false,
    };

    render() {
        return (
            <div className="nec-item">
                <div className="nec-data">
                    <div className="nec-item-icon" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                        <i className={classNames('fas','fa-caret-'+(this.state.isOpen ? 'down':'right'))}></i>
                    </div>
                    <div className="nec-item-label">{this.props.label ?? ''}</div>
                    <div className="nec-item-icon">
                        <i className="fas fa-trash"></i>
                    </div>
                </div>
                {this.state.isOpen && (
                    <div className="nec-children">
                        <DynamicTree path={this.props.path} onChange={this.props.onChange} />
                    </div>
                )}
            </div>
        );
    }
}

export class DynamicTree extends React.Component<any, {}> {
    public state = {
        value: '',
        items: this.props.items ?? [],
    };

    renderItems = (data: any) => {
        var output: any = [];
        for (let i = 0;i<data.length;i++) {
            var item = data[i];
            var path = (this.props.path ?? 0 ) + data.length;
            output.push(<DynamicTreeItem path={path} key={i} value={item.value} label={item.label} onChange={(items: any) => this.onChangeChild(items,i)} />);
        }
        return output;
    };

    onChangeChild = (data:any,index:any) => {
        const items = this.state.items;
        if(!items[index].children) {
            items[index].children = [];
        }
        items[index].children = data
        this.setState({items: items});
        if(this.props.onChange) {
            this.props.onChange(items);
        }
        
    }

    onClick = () => {
        var items = this.state.items;
        items.push({label: this.state.value,value:'123',path: (this.props.path ?? '0') +'-'+ items.length})
        
        if(this.props.onChange) {
            this.props.onChange(items);
        }
        this.setState({value: '',items});
    }

    render() {
        const items = [
            {label:'Teste',value:1,children: [
                {label: 'teste 1',value: 2}
            ]},
            {label: 'teste 1',value: 2}
        ];
        return (
            <div className="nec-tree">
                {this.renderItems(items)}
                <div className="nec-item-add">
                    {/* <InputGroup>
                        <Input onChange={(value: string) => this.setState({value})} value={this.state.value} />
                        <InputGroup.Button onClick={this.onClick} color={"green"}>
                            <i className="fas fa-plus"></i>
                        </InputGroup.Button>
                    </InputGroup> */}
                </div>
                {/* {JSON.stringify(this.state.items)} */}
            </div>
        );
    }
}
