import React from "react";
import { Button, Checkbox, Icon, IconButton, Input, Notification, Panel, PanelGroup, Table, Loader, SelectPicker, DatePicker } from "rsuite";
import { ReportMovementStock } from "../../reportsView/ReportMovementStock";
import { ReportServiceOrder } from "../../reportsView/ReportServiceOrder";
import axios from "../../../utilities/axios";
import { ReportStock } from "../../reportsView/ReportStock";
import { ReportBalance } from "../../reportsView/ReportBalance";
const { Column, HeaderCell, Cell } = Table;

export class ReportCustom extends React.Component<any, {}> {
  public state: any = {
    tireMeasurements: [],
    workshops: [],
    cities: [],
  }

  findTireMeasurements = async () => {
    await axios
      .get(`api/v1/tire_measurements/select`)
      .then((res) => {
        // console.log("tire_measurements", res.data.items)
        if (res.data.status == 200) {
          this.setState({ tireMeasurements: res.data.items })
        }
      })
  }

  findFantasyName = async () => {
    await axios
      .get(`api/v1/reports/workshops`)
      .then((res) => {
        if(res.data.status == 200) {
          this.setState({ workshops: res.data.data })
        }
      })
      .catch((e) => console.log(e.message))
  }

  findCities = async () => {
      await axios
      .get(`api/v1/reports/cities`)
      .then((res) => {
        if(res.data.status == 200) {
          this.setState({ cities: res.data.data })
        }
      })
      .catch((e) => console.log(e.message))
  }

  componentDidMount = () => {
    this.findTireMeasurements()
    this.findFantasyName()
    this.findCities()
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    // if(prevState.tireMeasurements !== this.state.tireMeasurements) {
    //   console.log('this.state.selectSchemaData', this.state.tireMeasurements)
    // }
  }
  render() {
    return (
      <PanelGroup accordion>
        <Panel header={<b>Rel. Movimentação de Estoque</b>} collapsible style={{ background: "white", marginBottom: 5 }} bordered>
          <ReportMovementStock
            tireMeasurements={this.state.tireMeasurements}
            workshops={this.state.workshops}
            cities={this.state.cities}
          />
        </Panel>
        <Panel header={<b>Contagem de Estoque</b>} collapsible style={{ background: "white" }} bordered>
          <ReportStock
            workshops={this.state.workshops}
            tireMeasurements={this.state.tireMeasurements}
          />
        </Panel>
        <Panel header={<b>Ordem de Serviço</b>} collapsible style={{ background: "white" }} bordered>
          <ReportServiceOrder 
            workshops={this.state.workshops}
          />
        </Panel>
        <Panel header={<b>Saldo</b>} collapsible style={{ background: "white", marginBottom: 5 }} bordered>
          <ReportBalance
            tireMeasurements={this.state.tireMeasurements}
            workshops={this.state.workshops}
            cities={this.state.cities}
          />
        </Panel>
      </PanelGroup>
    )
  }
}