import React from "react";
import { Equalizer } from "../../../utilities/Equalizer";
import { ModuleContext } from "../../authProvider/main";
// import { Widget } from "../Widget";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

class Favorites extends React.Component<any, {}> {
    // public widgetElement =
    static contextType = ModuleContext;

    onClick(route: string) {
        this.props.history.push(route.replace("{module}", this.context.name));
        this.context.setPage(route.split("/").pop());
    }

    getWidth() {
        var width = this.props.width ?? 12;
        var decimal = (12 / width) % 1;
        if(decimal > 0.19) {
            return Math.ceil(12 / width);
        } else {
            return Math.floor(12 / width) + 1;
        }
    }

    renderItems() {
        const output = [];
        for (var i = 0; i < this.props.items.length; i++) {
            let item = this.props.items[i];
            output.push(
                <div className={classNames("col-md-" + this.getWidth(), "mb-i-4")} key={i}>
                    <Equalizer>
                        <div onClick={() => this.onClick(item.to)} className="favorite-item">
                            <div className="icon">
                                <i className={classNames("fas", "fa-" + item.icon)}></i>
                            </div>
                            <div className="title">{item.name}</div>
                            <div className="bar" style={{ backgroundColor: this.context.color }}></div>
                        </div>
                    </Equalizer>
                </div>,
            );
        }
        return output;
    }

    render() {
        return (
            <div className="widget favorites">
                <div className="row">{this.renderItems()}</div>
            </div>
        );
    }
}

export default withRouter(Favorites);
