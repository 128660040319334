import React from "react";
import { Table } from "rsuite";
const { Column, HeaderCell, Cell } = Table;

export class ComparisonTable extends React.Component<any, {}> {
    renderColumns = () => {
        var output = [];
        var { errors } = this.props.data;
        var first = this.props.data.rows[0];
        for (let rowName in first) {
            output.push(
                <Column key={rowName} resizable align="left">
                    <HeaderCell>{rowName}</HeaderCell>
                    <Cell>
                        {(row: any, index: any) => {
                            var error = null;
                            if (errors[index] != null) {
                                if (errors[index][rowName]) {
                                    error = errors[index][rowName];
                                }
                            }
                            return (
                                <div>
                                    {row[rowName]}
                                    {error != null && (
                                        <div>
                                            <small className="text-danger">{error}</small>
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    </Cell>
                </Column>,
            );
        }
        output.push(
            <Column key="actions" fixed="right" width={40}>
                <HeaderCell>#</HeaderCell>
                <Cell>{(row:any,index:any) => {
                    if (errors[index] != null) {
                        return (<><i className="fas fa-times text-danger"></i></>);
                    } else {
                        return (<><i className="fas fa-check text-success"></i></>);
                    }
                }}</Cell>
            </Column>,
        );
        return output;
    };

    render() {
        return (
            <Table virtualized rowHeight={60} style={{ width: "100%",marginBottom:10 }} data={this.props.data.rows} height={220}>
                {this.renderColumns()}
            </Table>
        );
    }
}
