import axios from "../../utilities/axios";
import { Event } from "../Event"
import dayjs from "dayjs";

export class ItemAmountEvents extends Event {
  // calculateItems(obj: any) {
  //   let somaTotal = 0;
  //   console.log('length', obj.items.length)
  //   obj.items.forEach((item: any) => {
  //     console.log('item.item_amount:::', item.item_amount)
  //     console.log('item.item_unitary_value:::', item.item_unitary_value)
  //     let valorTotalItem = item.item_amount * item.item_unitary_value;
  //     somaTotal += valorTotalItem;
  //   });
  //   return somaTotal;
  // }

  public onBlur = async (value: string, setField: any, setState: any, formValues: any) => {
    this.setState('loadings', [
      'item_unitary_value', 'item_amount', 'order_custom_math_items_values', 
    ])
    setField("order_custom_math_items_values", value);
    this.setState('loadings', [])
  }
}
