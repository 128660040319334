import { Event } from "../Event";

export class ServiceOrderStateFkSituationIdEvents extends Event {
    onChange = (value: any, setField: any, setState: any) => {
        console.log("evento status os", this.globalProps);
        let service_order_cancel_motive;

        if (value === '45') {
            console.log('entrou required', value)
            this.setField("service_order_purchase_order_number", { required: true });
        }

        if (value === '16') { // status cancelado
            service_order_cancel_motive = this.globalProps.values.service_order_cancel_motive;
            this.setField("service_order_cancel_motive", { hidden: false, readonly: false });
            setField("service_order_cancel_motive", " ");
        } else {
            this.setField("service_order_cancel_motive", { hidden: true, readonly: true });
            setField("service_order_cancel_motive", service_order_cancel_motive);
        }
    };
}