import React from "react";
import { Button, ControlLabel, FormGroup, Modal } from "rsuite";
import axios from "../../utilities/axios";
// import { AxiosSelectPicker } from "../formfields/AxiosSelectPicker";
import { ListInput } from "../formfields/ListInput";

export class WidgetsModal extends React.Component<any, {}> {
    public state = {
        values: this.props.values !== undefined && this.props.values !== null ? this.props.values : {},
    };

    setValueByField = (field: string, value: any, min: number | null = null, max: number | null = null) => {
        const values: any = this.state.values;
        // if (min) {
        //     value = value < min ? min : value;
        // }
        // if (max) {
        //     value = value > max ? max : value;
        // }
        values[field] = value;
        this.setState({ values });
    };

    renderFieldsByWidget(type: string) {
        switch (type) {
            case "Favorites":
                const onChangeValues = (values: number[], data: any[]) => {
                    console.log('menus ::: ', values);
                    if (values.length > 0) {
                        axios
                            .get("/api/v1/menus/get", {
                                params: {
                                    module: this.props.module ?? undefined,
                                    ids: values,
                                },
                            })
                            .then((res) => {
                                var values: any = this.state.values;
                                if (res.data.items) {
                                    if (!values) {
                                        values = {};
                                    }
                                    values["items"] = res.data.items.map((item: any) => {
                                        return { name: item.menu_name, to: "/dashboard/{module}/" + item.menu_slug, icon: item.menu_icon, id: item.menu_id };
                                    });
                                    this.setState({ values });
                                }
                            });
                    } else {
                        this.setState({ values: { items: [] } });
                    }
                };

                const getOldValues = () => {
                    if (this.props.values && this.props.values.items) {
                        return this.props.values.items.map((item: any) => {
                            return item.id;
                        });
                    }
                    return [];
                };

                const getOldData = () => {
                    if (this.props.values && this.props.values.items) {
                        return this.props.values.items.map((item: any) => {
                            return { value: item.id, label: item.name };
                        });
                    }
                    return [];
                };

                return (
                    <>
                        <div className="col-md-12">
                            <FormGroup>
                                <ControlLabel>Menu</ControlLabel>
                                {/* <AxiosSelectPicker data={[]} api={{ url: "/api/v1/menus/select" }} /> */}
                                <ListInput
                                    overflow
                                    value={getOldValues()}
                                    options={getOldData()}
                                    onChange={onChangeValues}
                                    api={{
                                        url: "/api/v1/menus/select",
                                        uses: {
                                            module: this.props.module,
                                        },
                                    }}
                                />
                            </FormGroup>
                        </div>
                    </>
                );
        }
        return null;
    }

    onConfirm = () => {
        if (this.props.onConfirm) {
            this.props.onConfirm(this.state.values);
        }
    };

    render() {
        return (
            <>
                <Modal show={this.props.show && this.props.values !== null}>
                    <Modal.Header onHide={this.props.onCancel}>Configurações</Modal.Header>
                    <Modal.Body>
                        <div className="row">{this.renderFieldsByWidget(this.props.type)}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-between">
                            <div></div>
                            <div>
                                <Button color={"green"} onClick={this.onConfirm}>
                                    Salvar
                                </Button>
                                <Button onClick={this.props.onCancel}>Cancelar</Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
