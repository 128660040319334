import React from "react";
import { Loader, Tooltip, Whisper,Button , Notification, Table, Panel } from "rsuite";
import axios from "../../../utilities/axios";
import _ from "lodash";
import {CustomModal} from "../../customModal/CustomModal"
import TablePagination from "rsuite/lib/Table/TablePagination";


const { Column, HeaderCell, Cell } = Table;

export class TableGridLambda extends React.Component<any, {}> {
    public state = {
        show: false,
        loading: false,
        onSelect: null,
        data :  [],
        isLoading: false,
        value: null,
        valueProps: this.props.value,
        propsPsm: null,
        dataColumns: [],
        page: 1,
        displayLength: 10,
    }

    componentDidMount = async () =>{
        await axios
            .get(`api/v1/dev/list-table/lambda_configs`)
            .then((res) => {
                if(res.data.status == 200 ){
                    this.setState({
                        data: res.data.data
                    })
                }
            })
    }

    handleChangePage(dataKey:any) {
        console.log("Data", dataKey)
        this.setState({
          page: dataKey
        });
    }
    handleChangeLength(dataKey:any) {
        this.setState({
            page: 1,
            displayLength: dataKey
        });
    }

    public select: any = {};

    onChange = (item: any, valuesOmie: any) => {

    };

    isExecuteQuery = () => {
        let props = this.props.values;

    }
    
    isRenderBody = (data:any) => {
        this.setState({
            show:true
        });
        let jsonData = JSON.parse(data.development_create_table_columns)
        this.setState({
            dataColumns: jsonData
        })
    }

    delete = (data:any) => {
        console.log(data)
        axios
            .get(`/api/v1/dev/delete-lambda/${data.lambda_config_id}`)
            .then((res) => {
                if(res.data.status == 200){
                    Notification.success({
                        title: "Sucesso!",
                        description: 'Excluido com Sucesso',
                        duration: 6600,
                    });
                    setTimeout(this.props.history.go(0),7000)
                }
            })
    }

    render() {
        return (<>
            <Panel bordered bodyFill style={{ padding: 8, width: '100%' }}>
                <Table wordWrap loading={this.props.loading} height={400} data={this.state.data}>
                    <Column flexGrow={2} >
                        <HeaderCell>Crud Name:</HeaderCell>
                        <Cell dataKey="crud" />
                    </Column>
                    <Column fixed="right" width={100}>
                        <HeaderCell>Ações</HeaderCell>
                        <Cell className="link-group">
                            {(rowData: any) => {
                                //return this.openModal(rowData);                               
                                return (
                                <span>
                                    <Button color="red" onClick={()=> this.delete(rowData)} > Excluir </Button>
                                </span>
                                );
                            }}
                        </Cell>
                    </Column>
                </Table>
                {/* <TablePagination
                lengthMenu={[
                    {
                    value: 10,
                    label: 10
                    },
                    {
                    value: 20,
                    label: 20
                    }
                ]}
                activePage={this.state.page}
                displayLength={this.state.displayLength}
                total={this.state.data.length}
                onChangePage={(value:any) => this.handleChangePage(value)}
                onChangeLength={this.handleChangeLength}
                /> */}
            </Panel>
        </>)
    }
}
