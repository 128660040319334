import { Event } from "../Event";


export class UserMacroRegionsEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if(value.length > 0){
            setField("user_hub_validation", {
                checkedType: true
            })
        }
        if(value.length == 0){
            setField("user_hub_validation", {
                checkedType: false
            })
        }
    }
}