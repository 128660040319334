// alert(window.location.hostname)
var arr = window.location.href.split("/");
var url = arr[0] + "//" + arr[2]
const config: any = {
    url: `${url}`,
    module_colors: {
        comercial: "#4caf50",
        "compras-estoque": "#ff5733",
        laboratorio: "#323233",
        "assistencia-tecnica": "#ffb533",
        preventiva: "#6a30d1",
        menus: "#021738",
        relatorios: "#60e2be",
        "main": "#ff5733",
        "vendas-dibi": "rgb(200, 54, 177)",
    },
    main: [
        // { label: "Relatorios", url: "relatorios", color: "#4caf50", icon: "fai icon-crm", fullName: "Relatórios" },
    ],
    modules: [
        // { label: "Pedidos", url: "main", redirectUrl:"main/orders" ,color: "#ff5733", icon: "fai icon-cog", fullName: "Principal" },
        // { label: "CRM", url: "comercial", color: "#4caf50", icon: "fai icon-crm", fullName: "Comercial" },
        // { label: "Compras, Estoque e Recebimento", url: "compras-estoque", color: "#ff5733", icon: "fai icon-hand-truck", fullName: "Compras, Estoque e Recebimento" },
        // { label: "Configurações", url: "configuracoes", color: "#0e4194", icon: "fai icon-config", fullName: "Configurações" },
        // { label: "Configurações", url: "configuracoes", color: "#36a5eb", icon: "cogs", visible: false },
        { label: "Menus", url: "menus", color: "#0e4194", icon: "fas fa-home fa-lg", fullName: "Menus" },
    ],
    bussiness_unities: [
        // { label: "Relatorios", url: "relatorios", color: "#4caf50", icon: "fai icon-crm", fullName: "Relatórios" },
        // { label: "ATC", url: "assistencia-tecnica", color: "#ffb533", icon: "fai icon-pin", fullName: "Assistência Técnica" },
        // { label: "LAB", url: "laboratorio", color: "#323233", icon: "fai icon-wrench", fullName: "Laboratório" },
        // { label: "PREV", url: "preventiva", color: "#6a30d1", icon: "fai icon-cog", fullName: "Preventiva" },
        // { label: "DIBi", url: "vendas-dibi", color: "rgb(200, 54, 177)", icon: "fai icon-move", fullName: "Vendas DIBi" },
        // { label: "Comercial", url: "comercial", color: "#4caf50", icon: "dollar-sign" },

        // { label: "Laborátorio", url: "laboratorio", color: "#333333", icon: "flask" },
        // { label: "Assistencia Técnica", url: "assistencia-tecnica", color: "#ffb533", icon: "tools" },
        // { label: "Preventiva", url: "preventiva", color: "#6a30d1", icon: "ban" },
        // { label: "Preventiva Externa", url: "preventiva-externa", color: "#60e2be", icon: "angle-double-up" },
        // { label: "Vendas DIBi", url: "vendas-dibi", color: "#36a5eb", icon: "hand-holding-usd" },
    ],
};

export var getModule = function (moduleName: string) {
    for (var i in config.modules) {
        if (config.modules[i].url === moduleName) {
            return config.modules[i];
        }
    }
    for (var i in config.main) {
        if (config.main[i].url === moduleName) {
            return config.main[i];
        }
    }
    for (var x in config.bussiness_unities) {
        if (config.bussiness_unities[x].url === moduleName) {
            return config.bussiness_unities[x];
        }
    }
};
export default config;
