import { Component } from "react";
import { Button, Table, Notification } from "rsuite";
import fs from "fs";
import JSZip from "jszip";
import dayjs from "dayjs";
import axios from "../../utilities/axios";
// import Base64Downloader from 'react-base64-downloader';

interface Props {
    data: Array<Record<string, any>>;
}

const { Column, HeaderCell, Cell, Pagination, ColumnGroup } = Table;

export class TableDownload extends Component<Props> {
    // public state: any = {
    //     data: [
    //         {
    //             "xml_url": "./storage/returns/08-09-2023/1694171636_NFe35230507433281000115550010000056821019533507_edited.xml",
    //             "xml_name": "NFe35230507433281000115550010000056821019533507"
    //         },
    //         {
    //             "xml_url": "./storage/returns/08-09-2023/1694171636_NFe35230507433281000115550010000056831473477517_edited.xml",
    //             "xml_name": "NFe35230507433281000115550010000056831473477517"
    //         }
    //     ],
    // };

    dowloadItem = (rowData: any) => {
        const url = rowData.xml_url.split("./").pop();
        axios
            .get(`http://${window.location.host}/${url}`, {
                responseType: "blob",
            })
            .then((res) => {
                const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
                const fileName = rowData.xml_url.split("./").pop();
                const aTag = document.createElement("a");
                aTag.href = blobUrl;
                aTag.setAttribute("download", fileName);
                document.body.appendChild(aTag);
                aTag.click();
                aTag.remove();
            })
            .catch((e) => {
                // console.log('meu Erro:::> ',e)
                Notification.error({
                    title: "Error!!",
                    description: e.response,
                });
            });
    };

    dowloadAllFiles = async () => {
        const zip = new JSZip();
        const filesNames: any = this.props.data
        const filesFolder: any = zip.folder('files')
        for(const item  of filesNames){
            const url = item.xml_url.split("./").pop();
            await axios
            .get(`http://${window.location.host}/${url}`, {
                responseType: "blob",
            })
            .then(async (res) => {
                // console.log(res.data)
                const fileName = item.xml_url.split("/").pop().split('.xml').shift();
                filesFolder.file(`${fileName}.xml`, res.data)
            })
            .catch((e) => {
                // console.log('meu Erro:::> ',e)
                Notification.error({
                    title: "Error!!",
                    description: e.response,
                });
            });
        }

        let folderName = this.props.data[0].xml_url.split("/storage/returns/").pop().split('/').shift()
        folderName = `${folderName}-${dayjs().unix()}`
        const compressed: any = await zip.generateAsync({ type: 'blob' }).then((content) => {
            // console.log({ content });
            return content;
        }).catch((e) => {
            console.log(e)
        })

        const blobUrl = window.URL.createObjectURL(new Blob([compressed]));
        const fileNameZip = `${folderName}.zip`
        const aTag = document.createElement("a");
        aTag.href = blobUrl;
        aTag.setAttribute("download", fileNameZip);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    render() {
        return (
            <>
                <Table data={this.props.data} rowHeight={60} height={600}>
                    <Column flexGrow={4}>
                        <HeaderCell>Nome do arquivo</HeaderCell>
                        <Cell dataKey="xml_name" />
                    </Column>
                    <Column flexGrow={1} align="center">
                        <HeaderCell>Dowload</HeaderCell>
                        <Cell>
                            {(rowData: any, index: any) => {
                                return (
                                    <>
                                        <Button
                                            onClick={(e) => this.dowloadItem(rowData)}
                                            color="blue"
                                            circle
                                        >
                                            <i className="fas fa-file-download"></i>
                                        </Button>
                                    </>
                                );
                            }}
                        </Cell>
                    </Column>
                </Table>
                <Button
                    onClick={() => this.dowloadAllFiles()}
                    color="orange"
                >
                    Download de todos arquivos
                </Button>
            </>
        );
    }
}
