import React from "react";
import { Tooltip, Whisper } from "rsuite";

export class ErrorPopover extends React.Component<any, {}> {
    renderExtraErrors = () => {
        var errors = this.props.errors ? this.props.errors : [];
        var output = [];
        for (var i in errors) {
            output.push(
                <Whisper key={i} trigger="click" placement="topStart" speaker={<Tooltip>{errors[i]}</Tooltip>}>
                    <div style={{ color: "orange",marginLeft:4 }}>
                        <i className="fas clickable fa-exclamation-triangle"></i>
                    </div>
                </Whisper>
            );
        }
        return output;
    };

    render() {
        return (
            <div className="click d-flex">
                <Whisper trigger="click" placement="topStart" speaker={<Tooltip>{this.props.title}</Tooltip>}>
                    <div style={{ color: "red" }}>{this.props.children ? "!!"+this.props.children+"!!": ''}</div>
                </Whisper>
                {this.renderExtraErrors()}
            </div>
        );
    }
}
