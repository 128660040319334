import React from "react";
import { Badge, Button, Loader, Popover, Whisper } from "rsuite";
import axios from "../../utilities/axios";
import { io } from "../../utilities/io";

class NotificationItem extends React.Component<any, {}> {
    renderType() {
        switch (this.props.type) {
            case "Stock":
                return <i className="fas fa-cubes text-info"></i>;
            case "OS":
                return <i className="fas fa-wrench text-warning"></i>;
            default:
                return <i className="fas fa-info-circle text-primary"></i>;
        }
    }

    render() {
        return (
            <div className="notification">
                <div className="notification-icon">
                    <i className="fas fa-info-circle text-primary"></i>
                </div>
                <div className="notification-icon">{this.renderType()}</div>
                <div className="notification-text" style={{ color: "rgb(133, 133, 133)", fontWeight: "normal" }}>
                    {this.props.message}
                </div>
                <div className="notification-meta">
                    <Button size="lg" onClick={() => this.props.onClick(this.props.id, this.props.key)}>✔</Button>
                </div>
            </div>
        );
    }
}

class Notifications extends React.Component<any, {}> {
    renderItems() {
        const groupedMessages: { [key: string]: any[] } = {};

        for (const i in this.props.messages) {
            const data = this.props.messages[i];
            const createdBy = data.created_by || '';

            if (!groupedMessages[createdBy]) {
                groupedMessages[createdBy] = [];
            }

            groupedMessages[createdBy].push(
                <NotificationItem onClick={this.props.onClick} key={i} id={data.id} type={data.type ?? "primary"} date={data.date} message={data.message} />
            );
        }

        const output: JSX.Element[] = [];
        for (const key in groupedMessages) {
            output.push(
                <div key={key}>
                    <h5>{key}</h5>
                    <div>
                        {groupedMessages[key]}
                    </div>
                </div>
            );
        }

        return output;
    }

    render() {
        if (this.props.loading) {
            return <Loader speed={"slow"} size="md" />;
        }
        if (this.props.messages.length <= 0) {
            return (
                <div style={{ opacity: 0.6 }}>Nenhuma notificação encontrada.</div>
            );
        }
        return this.renderItems();
    }
}

export class NotificationsIcon extends React.Component<any, {}> {
    public state = {
        messages: [],
        loading: false,
        hasNotification: false,
        isOpen: false
    };
    componentDidMount() {
        this.loadMessages();
        io.on("newMessage", (message: object) => {
            //
            // alert(JSON.stringify(message));
            this.setState({ messages: [...this.state.messages], hasNotification: true });
        });
    }

    loadMessages = () => {
        this.setState({ loading: true });
        // if (this.state.messages.length <= 0) {
        axios.get("/api/redis/messages").then((res) => {
            if (res.data.result) {
                // if(res.data.result )
                this.setState({ messages: [...res.data.result], loading: false });
            }
        });
        // }
    };

    delete = (id: string | number, index: number) => {
        axios.get("/api/redis/read/message/" + id).then((res) => {
            if (res.data.status === 200) {
                // var messages = [...this.state.messages];
                // messages.splice(index,1);
                this.loadMessages();
                // this.setState({ messages },() => {});
            }
        });
        // alert("CLICK"+id);
    };

    clearNotifications = () => {
        axios.post("/api/v1/notifications/delete").then((res) => {
            if (res.data.query) {
                this.loadMessages();
            }
        });
    }

    render() {
        return (
            <>
                <Whisper
                    speaker={
                        <Popover style={{ maxHeight: "300px", overflowY: "auto" }}>
                            <Notifications loading={this.state.loading} onClick={this.delete} messages={this.state.messages} />
                            {!this.state.loading && this.state.messages.length > 0 && (
                                <Button
                                    style={{ float: "right" }}
                                    onClick={() => this.clearNotifications()}
                                    size="sm"
                                    appearance="ghost"
                                >
                                    Limpar Notif.
                                </Button>
                            )}
                        </Popover>
                    }
                    trigger={"click"}
                    onClose={() => this.setState({ isOpen: false })}
                    onClick={() => {
                        this.setState({ hasNotification: false, isOpen: true });
                        this.loadMessages();
                    }}
                    // open={true}
                    placement={"bottomStart"}>
                    <span className="mr-4">
                        {/* {this.state.isOpen === false && this.state.hasNotification === true && (
                            <Tooltip visible={true} placement="bottomEnd">
                                Você possui novas notificações!
                            </Tooltip>
                        )} */}
                        <Badge maxCount={40} content={this.state.messages.length > 0 ? this.state.messages.length : false}>
                            <i className="fas ml-4 mr-2 fa-fw fa-bell"></i>
                        </Badge>
                        {/* <i className={classNames("fas fa-fw ml-4 fa-bell")}>{this.state.hasNotification === true && <div className="bell-ball">•</div>}</i> */}
                    </span>
                </Whisper>

                {/* {JSON.stringify(this.state.messages)} */}
            </>
        );
    }
}
