//import react
import _, { values } from "lodash";
// import guid from ""
import React, { Component } from "react";
import { Button, ControlLabel, FormGroup, HelpBlock, Input, InputGroup, Loader, Modal, Notification, TagPicker, Uploader } from "rsuite";
import { mailConfigs } from "../../config/mails";
import axios from "../../utilities/axios";
import { FileInput } from "../formfields/FileInput";
import { WYSIWYG } from "../formfields/WYSIWYG";

export class MailerModal extends React.Component<any, {}> {
    //react state of, email subject, email content, cc, cco
    public state = {
        isOpen: this.props.isOpen,
        emailSubject: this.props.emailSubject ?? "",
        emailContent: `Prezado Cliente.

        Seguem em anexo o(s) orçamento(s) para vossa aprovação conforme análise técnica
        
        Qualquer dúvida, estamos a disposição`,
        to: this.props.to ?? [],
        cc: this.props.cc ?? [],
        cco: this.props.cco ?? [],
        loading: false,
        ccEnabled: false,
        ccoEnabled: false,
        emails: [],
        files: [],
        key: {},
    };

    uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    public guid = this.uuidv4();

    private loadTemplate() {
        if (this.props.isOpen == true && this.props.template !== undefined) {
            const templateSelected = this.props.template;
            // alert(templateSelected)
            if (mailConfigs[templateSelected] !== undefined) {
                const template = mailConfigs[templateSelected];
                if (template) {
                    if (template?.events["onInit"] !== undefined) {
                        this.setState({ loading: true });
                        template?.events["onInit"](this.props, () => {
                            this.setState({ loading: false });
                        });
                    }
                    var content = template.content;
                    var subject = template.subject;
                    if (typeof content == "function") {
                        content = content(this.props.data);
                    } else if (typeof content == "string") {
                        for (var i in this.props.data) {
                            content = content.split("{{" + i + "}}").join(this.props.data[i]);
                            subject = subject.split("{{" + i + "}}").join(this.props.data[i]);
                        }
                    }
                    this.setState({
                        emailSubject: subject ?? "Template de email sem assunto.",
                        emailContent: content,
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.loadTemplate();


        // mateurs
    }




    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.to !== prevProps.to) {
            //alert(this.props.to)
            var emails = _.uniqBy(
                _.map([...(this.props.cc ?? []), ...(this.props.cco ?? []), ...(this.props.to ?? []), ...(this.state.emails ?? [])], (item) => {
                    return {
                        label: item,
                        value: item,
                    };
                }),
                "value",
            );
            this.setState({
                to: _.uniq([...this.state.to, ...this.props.to]),
                emails,
            });
        }
        if (this.props.isOpen !== prevProps.isOpen) {
            this.loadTemplate();
            var emails = _.uniqBy(
                _.map([...(this.props.cc ?? []), ...(this.props.cco ?? []), ...(this.props.to ?? [])], (item) => {
                    return {
                        label: item,
                        value: item,
                    };
                }),
                "value",
            );
            var to = _.uniq(this.props.to ?? []);
            var cc = _.uniq(this.props.cc ?? []);
            var ccEnabled = false;
            if (cc.length > 0) {
                ccEnabled = true;
            }
            var cco = _.uniq(this.props.cco ?? []);
            var ccoEnabled = false;
            if (cco.length > 0) {
                ccoEnabled = true;
            }
            var emailSubject = this.props.emailSubject ?? "";
            var files = this.props.files ?? [];
            this.setState({
                emailSubject,
                to,
                cco,
                files,
                cc,
                ccEnabled,
                ccoEnabled,
                emails,
                isOpen: this.props.isOpen,
            });
        }
        if (this.props.loading !== prevProps.loading) {
            this.setState({
                loading: this.props.loading,
            });
        }
    }

    renderMenuItem = (option: any) => {
        if (option?.props?.children?.length == 3) {
            const children = [...option?.props?.children];
            children[0] = React.cloneElement(children[0], {
                children: <span>Incluir email "</span>,
            });
            return React.cloneElement(option, {
                children,
                // disabled: true
            });
        } else {
            return option;
        }
    };

    onChangeEmail = (value: string[], type: "cc" | "cco" | "to" = "to") => {
        // console.log(value)
        if (value) {
            for (var i = 0; i < value.length; i++) {
                const addedItem = value[i];
                if (addedItem) {
                    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(addedItem);
                    if (!isValidEmail) {
                        value.splice(i, 1);
                    }
                }
            }

            this.setState({
                [type]: value,
            });
        }
        //alert(JSON.stringify(value));
    };

    sendEmail = () => {
        if (this.state.to.length == 0) {
            Notification.error({
                title: "Erro",
                description: "Por favor, selecione ou preencha um email para enviar.",
            });
            return;
        }
        if (this.state.emailSubject.length == 0) {
            Notification.error({
                title: "Erro",
                description: "Por favor, preencha o assunto do email.",
            });
            return;
        }
        if (this.state.emailContent.length == 0) {
            Notification.error({
                title: "Erro",
                description: "Por favor, preencha o conteúdo do email.",
                // title: "Por favor, preencha o conteúdo do email.",
            });
            return;
        }
        this.setState({ loading: true });
        const emailData = {
            to: this.state.to,
            cc: this.state.cc,
            cco: this.state.cco,
            subject: this.state.emailSubject,
            content: this.state.emailContent,
            files: _.map(this.state.files, (item: any) => item.url),
            guid: this.guid,
        };
        axios
            .post("/api/v1/email/send", emailData)
            .then((res) => {
                if (res.status === 200) {
                    const status = 'avanca'
                    Notification.success({
                        title: "Email enviado com sucesso!",
                    });
                    this.props.onHide();

                    if (this.props.afterSend) {
                        this.props.afterSend(this.props.psmId);
                    }
                }
            })
            .catch((err) => {
                Notification.error({
                    title: "Erro",
                    description: err.response.data.error,
                });
            })
            .finally(() => {
                this.setState({ loading: false });
                // if (this.props.onHide) {
                //     this.props.onHide();
                // }
            });
    };

    render() {

        return (
            <Modal size={"lg"} show={this.props.isOpen} overflow={false}>
                <Modal.Header
                    closeButton={!this.state.loading}
                    onHide={() => {
                        this.setState({ isOpen: false });
                        if (this.props.onHide) {
                            this.props.onHide();
                        }
                    }}>
                    <h4>Enviar email</h4>
                </Modal.Header>
                <Modal.Body>
                    {this.state.loading == true && (
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Loader size={"md"} />
                        </div>
                    )}
                    {this.state.loading == false && (
                        <div className="row">
                            <div className="col-md-12">
                                <FormGroup className="mb-4">
                                    <ControlLabel>Para</ControlLabel>
                                    <InputGroup>
                                        <TagPicker

                                            //  placeholder="Escolha ou digite um email."

                                            value={this.state.to}
                                            onChange={(value) => this.onChangeEmail(value, "to")}
                                            renderMenuItem={this.renderMenuItem}
                                            className="w-100"
                                            cleanable
                                            creatable
                                            data={this.state.emails}
                                        />
                                        <InputGroup.Button color={this.state.ccEnabled ? "cyan" : undefined} onClick={() => this.setState({ ccEnabled: !this.state.ccEnabled })}>
                                            CC
                                        </InputGroup.Button>
                                        <InputGroup.Button color={this.state.ccoEnabled ? "cyan" : undefined} onClick={() => this.setState({ ccoEnabled: !this.state.ccoEnabled })}>
                                            CCO
                                        </InputGroup.Button>
                                        {this.props.children}
                                    </InputGroup>
                                    {this.state.ccEnabled && (
                                        <InputGroup className="mt-2">
                                            <InputGroup.Addon style={{ width: 50 }}>CC</InputGroup.Addon>
                                            <TagPicker
                                                placeholder="Escolha ou digite um email."
                                                value={this.state.cc}
                                                onChange={(value) => this.onChangeEmail(value, "cc")}
                                                renderMenuItem={this.renderMenuItem}
                                                className="w-100"
                                                cleanable
                                                creatable
                                                data={this.state.emails}
                                            />
                                        </InputGroup>
                                    )}
                                    {this.state.ccoEnabled && (
                                        <InputGroup className="mt-2">
                                            <InputGroup.Addon style={{ width: 50 }}>CCO</InputGroup.Addon>
                                            <TagPicker
                                                placeholder="Escolha ou digite um email."
                                                value={this.state.cco}
                                                onChange={(value) => this.onChangeEmail(value, "cco")}
                                                renderMenuItem={this.renderMenuItem}
                                                className="w-100"
                                                cleanable
                                                creatable
                                                data={this.state.emails}
                                            />
                                        </InputGroup>
                                    )}
                                    {/* <Input onChange={(value) => this.setState({emailSubject: value})} value={this.state.emailSubject} /> */}
                                    <HelpBlock>Obrigatório</HelpBlock>
                                </FormGroup>
                            </div>
                            <div className="col-md-12">
                                <FormGroup className="mb-4">
                                    <ControlLabel>Assunto</ControlLabel>
                                    <Input onChange={(value) => this.setState({ emailSubject: value })} value={this.state.emailSubject} />
                                    <HelpBlock>Obrigatório</HelpBlock>
                                </FormGroup>
                            </div>
                            <div className="col-md-12">
                                <FormGroup>
                                    <ControlLabel>Conteúdo</ControlLabel>
                                    <WYSIWYG onChange={(value: any) => this.setState({ emailContent: value })} value={this.state.emailContent} />
                                    {/* <div dangerouslySetInnerHTML={{ __html: this.state.emailContent }}></div> */}
                                    <HelpBlock>Obrigatório</HelpBlock>
                                </FormGroup>
                            </div>
                            <div className="col-md-12">
                                <FormGroup>
                                    <FileInput value={this.state.files} guid={this.guid} relation={"email-upload"} name={"email-attachments"} />
                                </FormGroup>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button loading={this.state.loading} onClick={this.props.onHide}>
                        <i className="fas fa-fw mr-2 fa-times"></i>
                        Cancelar
                    </Button>
                    <Button onClick={this.sendEmail} loading={this.state.loading} color="green">
                        <i className="fas fa-fw mr-2 fa-paper-plane"></i>
                        Enviar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
