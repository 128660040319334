import React from "react";
import { Loader, Modal, ModalProps } from "rsuite";

interface LoadingModalProps extends ModalProps {
    loading?: boolean;
}

export default class LoadingModal extends React.Component<LoadingModalProps, {}> {
    render() {
        return (
            <Modal {...this.props} >
                {this.props.header && <Modal.Header closeButton onHide={this.props.onHide}>{this.props.header}</Modal.Header>}
                {this.props.loading == true && (
                    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',paddingBottom:15}}>
                        <Loader size={"md"} />
                    </div>
                )}
                
                <div style={{maxHeight: '75vh',overflow: 'auto'}}>
                {this.props.loading !== true && this.props.children}
                </div>
            </Modal>
        );
    }
}
