import { Event } from "../Event";
import axios from "../../utilities/axios";
import Axios from 'axios';

export class UserAddressCepEvents extends Event {
    onBlur = (value: any, setField: any, setState: any) => {
        if (value.length === 8) { // cep
            setState("loadings", [
                "user_address_street",
                "user_address_district",
                "user_address_fk_state_id",
                "user_address_fk_city_id"
            ]);

            Axios
                .get("https://viacep.com.br/ws/" + value + "/json/")
                .then((res: any) => {
                    // console.log("consulta cep", res.data);

                    if (res.data) {
                        setField("user_address_street", res.data.rua);
                        setField("user_address_district", res.data.bairro);

                        var stateId: any = undefined;
                        axios
                            .get("/api/v1/states/select", {
                                params: {
                                    state: res.data.uf
                                },
                            })
                            .then((res: any) => {
                                const { items } = res.data;
                                // console.log("items states", items)
                                const { label, value } = items[0] ?? undefined;
                                stateId = value;
                                if (label && value) {
                                    setField("user_address_fk_state_id", { label, value: value.toString() });
                                }
                            })
                            .catch((e) => { })
                            .finally(() => {
                                // if (this.globalProps.match.url.includes('/add')) {
                                    axios
                                        .get("/api/v1/cities/select", {
                                            params: {
                                                state: stateId,
                                                city_ibge_code: res.data.ibge
                                            },
                                        })
                                        .then((res) => {
                                            var { items } = res.data;
                                            // console.log("items cities", items)
                                            var { label, value } = items[0] ?? undefined;
                                            // console.log("label e values", label, value);
                                            if (label && value) {
                                                this.setFieldValue("user_address_fk_city_id", value);
                                            }
                                        })
                                        .catch((e) => { })
                                        .finally(() => {
                                            setState("loadings", []);
                                        });
                                // }
                            });

                        setField("user_address_street", res.data.logradouro);
                        setField("user_address_district", res.data.bairro);
                        // setField("user_email", res.data.email);
                    }
                })
                .catch((e) => {
                    setState("loadings", []);
                })
                .finally(() => {
                    // setState("loadings", []);
                });
        }
    };
}