import { Event } from "../Event";
import dayjs from "dayjs";

export class BalanceAdjustmentEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any, setFieldValue: any) => {
        if (this.globalProps.match.url.includes('/add')) {
            this.setField("balance_adjustment_created_at", { mask: ["99/99/9999"] });
            this.setFieldValue("balance_adjustment_created_at", dayjs().format('DD-MM-YYYY hh:mm:ss.sss'));
        }
    }
};