import classNames from "classnames";
import React from "react";
import { Button, Input, InputGroup, Modal, Notification } from "rsuite";

export class ChangePassword extends React.Component<any, {}> {
    public state = {
        showModal: false,
        showPassword: false,
        showPasswordConfirm: false,
        newPassword: "",
        confirmNewpassword: ""
    }

    submmitedForm() {
        if (this.state.newPassword != this.state.confirmNewpassword) {
            Notification.error({
                title: "Erro!",
                description: 'As Senhas devem ser iguais!',
                placement: "topEnd",
            });
            return
        }

        this.props.values.user_password = this.state.newPassword

        this.onCancel()
    }

    onCancel() {
        this.setState({
            showModal: false,
            showPassword: false,
            newPassword: "",
            confirmNewpassword: ""
        })
    }

    renderModal() {
        return (<>
            <Modal size="xs" show={this.state.showModal} onHide={() => this.onCancel()}>
                <Modal.Header onHide={() => this.setState({ showModal: false })} closeButton={true}>
                    <Modal.Title>
                        <i className="fas fa-fw mr-2 fa-cog" style={{ color: "#0096a0", fontSize: 24 }}></i>
                        Alterar Senha
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display:"flex",margin:"auto",flexDirection:"column",alignItems:"center"}}>
                        <div>
                            <label>Nova Senha:</label>
                            <InputGroup inside style={{ width: 300 }}>
                                <Input onChange={(value) => this.setState({ newPassword: value })} type={this.state.showPassword ? 'text' : 'password'} />
                                <InputGroup.Button onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                                    <i className={classNames("fas fa-fw", "fa-" + (!this.state.showPassword ? "eye" : "low-vision"))}></i>
                                </InputGroup.Button>
                            </InputGroup>
                        </div>
                        <div>
                            <label style={{ marginTop: 38 }}>Confirmar Nova Senha:</label>
                            <InputGroup inside style={{ width: 300 }}>
                                <Input onChange={(value) => this.setState({ confirmNewpassword: value })} type={this.state.showPasswordConfirm ? 'text' : 'password'} />
                                <InputGroup.Button onClick={() => this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm })}>
                                    <i className={classNames("fas fa-fw", "fa-" + (!this.state.showPasswordConfirm ? "eye" : "low-vision"))}></i>
                                </InputGroup.Button>
                            </InputGroup>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.onCancel()} appearance="primary" color="red">Cancelar</Button>
                    <Button onClick={() => this.submmitedForm()} appearance="primary">Confirmar Alteração</Button>
                </Modal.Footer>
            </Modal>
        </>)
    }

    render() {
        return (<>
            {this.renderModal()}
            <Button style={{ marginTop: 25 }} onClick={() => this.setState({ showModal: true })} appearance="primary" color="violet">ALTERAR SENHA</Button>
        </>)
    }
}