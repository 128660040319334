import axios from "../../utilities/axios";
import React from "react";
import { Button, Modal, Table, Panel, Toggle, DatePicker, SelectPicker } from "rsuite";
import { AxiosSelectPicker } from "../formfields/AxiosSelectPicker";
import dayjs from "dayjs";
import TablePagination from "rsuite/lib/Table/TablePagination";
import _ from "lodash"

const { Column, HeaderCell, Cell } = Table;

export class CustomModal extends React.Component<any, {}> {

    public state: any = {
        selectedNfe: [],
        itens: [],
        searchData: [],
        dateIni: dayjs().format('DD/MM/YYYY'),
        endDate: dayjs().add(7,'day').format('DD/MM/YYYY'),
        page: 1,
        dataTableNotes: this.props.omieData,
        loading: this.props.loadings
    }
    public locale = {
        sunday: "DON",
        monday: "SEG",
        tuesday: "TER",
        wednesday: "QUA",
        thursday: "QUI",
        friday: "SEX",
        saturday: "SAB",
        ok: "Ok",
        today: "Hoje",
        yesterday: "Ontem",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
    };

    handleChangePage = (page: any) => {
        this.setState({loading: true})
        console.log('handle page ::: ', this.props.omieData)
        try{
            axios
            .post(`/api/v1/omie/pagination/${page}`,{
                data: this.props.omieData
            })
            .then((res) => {
                console.log('response ::: ', res.data)
                this.setState({
                    dataTableNotes: res.data.ResponseOmie,
                    
                })
            })
            .catch((err) => {console.log(err)})
            .finally(() => {
                this.setState({loading: false})
            })
            
            console.log(this.state.dataTableNotes)
        }catch(e){console.log(e)}
        this.setState({
            page: page
        });
    }
    handleChangeLength(dataKey:any) {
        console.log('entrei em baico')
        this.setState({
            page: 1,
            displayLength: dataKey
        });
    }

    render() {
        if (this.props.show === false) {
            return null;

        }
        return (
            <Modal show={this.props.show} className={this.props.widthModal} width={this.props.widthNumber} onHide={this.props.closeModal}>
                <Modal.Header>
                    <h4>{this.props.title ?? "Deseja mesmo sair?"}</h4>
                </Modal.Header>
                <Modal.Body>
                    {this.props.body}
                    {this.props.tableOmie !== false && (<>
                        <div style={{ display: 'Flex', flexDirection: "row", width: '800px', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                Data inicial
                                <DatePicker
                                    style={{ width: 224 }}
                                    locale={this.locale}
                                    format="DD/MM/YYYY"
                                    onChange={(date: any) => this.setState({ searchData: { ...this.state.searchData, starteDate: dayjs(date).format('DD/MM/YYYY'), dateIni:  dayjs(date).format('DD/MM/YYYY')} })}
                                    placeholder="DD/MM/AAAA"
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                Data Final
                                <DatePicker
                                    style={{ width: 224 }}
                                    locale={this.locale}
                                    format="DD/MM/YYYY"
                                    onChange={(date: any) => this.setState({ searchData: { ...this.state.searchData, endData: dayjs(date).format('DD/MM/YYYY') },  endDate: dayjs(date).format('DD/MM/YYYY')})}
                                    placeholder="DD/MM/AAAA"
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                Selecione a Unidade Mx
                                <AxiosSelectPicker
                                    data={this.props.selectCompanie}
                                    api={'companies'}
                                    onChange={(value: any) => this.setState({ searchData: { ...this.state.searchData, companie_id: value } })}
                                    style={{ width: 224 }}
                                    placeholder="Selecione..."
                                />
                            </div>
                            <div style={{ marginTop: '20px' }}>
                                <Button color={"green"} onClick={(e: any) => this.props.searchNotes(this.state.searchData)}>Pesquisar</Button>
                            </div>
                        </div>
                        <div style={{ display: 'inline-flex', marginTop: '10px' }}>
                            <Panel bordered bodyFill style={{ padding: 8, width: 720 }}>
                                <Table wordWrap loading={this.state.loading} height={400} width={700} 
                                    data={this.state.dataTableNotes?.documentosEncontrados?.length > 1 ? _.orderBy(this.state.dataTableNotes?.documentosEncontrados, ['dataFormatada', 'desc']) : _.orderBy(this.props.omieData.documentosEncontrados, ['dataFormatada', 'desc'])}
                                    >
                                    <Column width={100} resizable>
                                        <HeaderCell>Nº Nota Fiscal</HeaderCell>
                                        <Cell dataKey="nNf" />
                                    </Column>
                                    <Column width={50} resizable>
                                        <HeaderCell>Série</HeaderCell>
                                        <Cell dataKey="serieNfe" />
                                    </Column>
                                    <Column width={120} resizable>
                                        <HeaderCell>Data de Emissão</HeaderCell>
                                        <Cell dataKey="dataEmi" />
                                    </Column>
                                    <Column width={150} resizable>
                                        <HeaderCell>Nº CNPJ</HeaderCell>
                                        <Cell dataKey="CNPJ" />
                                    </Column>
                                    <Column width={150} resizable>
                                        <HeaderCell>Nome Fantasia</HeaderCell>
                                        <Cell dataKey="nomeFantasia" />
                                    </Column>
                                    <Column fixed="right" width={100}>
                                        <HeaderCell>Ações</HeaderCell>
                                        <Cell className="link-group">
                                            {(rowData: any) => {
                                                //return this.openModal(rowData);                               
                                                return (
                                                    <span>
                                                        <Button onClick={() => this.props.onClickNfe(rowData)} > Selecionar </Button>
                                                    </span>
                                                );
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                                <TablePagination
                                    lengthMenu={[
                                        {
                                            value: 10,
                                            label: 10
                                        }
                                    ]}
                                    activePage={this.props.omieData.nPagina}
                                    displayLength={this.props.omieData.nRegistros}
                                    total={this.props.omieData.nTotRegistros}
                                    onChangePage={this.handleChangePage}
                                    onChangeLength={this.handleChangeLength}
                                />
                            </Panel>
                            <Panel bordered bodyFill style={{ padding: 8, width: 670 }}>
                                <Table wordWrap loading={this.props.loading} height={400} width={650} data={this.props?.OmieItens?.[0] ?? []}>
                                    <Column width={95} resizable>
                                        <HeaderCell>Nº Item/XML</HeaderCell>
                                        <Cell dataKey="numItem" />
                                    </Column>
                                    <Column width={220} resizable>
                                        <HeaderCell>Codigo do Produto</HeaderCell>
                                        <Cell dataKey="codProd" />
                                    </Column>
                                    <Column width={220} resizable>
                                        <HeaderCell>Descrição do item</HeaderCell>
                                        <Cell dataKey="descriptionItens" />
                                    </Column>
                                    <Column width={140} resizable>
                                        <HeaderCell>NCM</HeaderCell>
                                        <Cell dataKey="ncmProd" />
                                    </Column>
                                    <Column fixed="right" width={180}>
                                        <HeaderCell>Ações</HeaderCell>
                                        <Cell className="link-group">
                                            {(rowData: any) => {
                                                //return this.openModal(rowData);                               
                                                return (<div>
                                                    <span>
                                                        <Button onClick={() => this.props.onChange(rowData, this.props.OmieItens)} color={"green"}>Selecionar</Button>
                                                    </span>
                                                    <span>
                                                        <Button loading={this.props.loading} onClick={() => this.props.isCancelItem(rowData, this.props.OmieItens)} color={"red"} style={{ marginLeft: "5px" }}> Anular </Button>
                                                    </span>
                                                </div>);
                                            }}
                                        </Cell>
                                    </Column>
                                </Table>
                            </Panel>
                        </div>
                    </>)}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.surfaceReport == true &&
                        <div style={{ display: "grid", flexDirection: "column", width: "150px", justifyContent: "space-between", textAlign: "start", alignContent: "center", marginBottom: "15px", position: "absolute" }}>
                            <label>Laudo Superficial</label>
                            <Toggle style={{ marginTop: "8px" }} />
                        </div>
                    }
                    {this.props.showConfirm !== false && <Button loading={this.props.loading} onClick={this.props.onConfirm} color={this.props.confirmColor ?? 'red'}>
                        {this.props.confirmText ?? 'Confirmar'}
                    </Button>}
                    {this.props.showInviteButton == true &&
                        <Button disabled={this.props.loading} onClick={this.props.inviteButton} color={"green"}>
                            Concluir
                        </Button>
                    }
                    <Button disabled={this.props.loading} loading={this.props.loading} onClick={this.props.onCancel} color={this.props.cancelColor ?? undefined}>
                        {this.props.cancelText ?? 'Cancelar'}
                    </Button>
                    {this.props.showbotaoclose == true && <Button disabled={this.props.loading} onClick={this.props.funcaoclose} color={"red"}>
                        Fechar
                    </Button>}

                </Modal.Footer>
            </Modal>
        );
    }
}
