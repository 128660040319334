import dayjs from "dayjs";
import React from "react";
import { Button, ControlLabel, DatePicker, FormGroup, Input, Modal,Notification } from "rsuite";
import axios from "../../utilities/axios";
import { Event } from "../Event";

class UserFilesModalComponent extends React.Component<any, {}> {
    public state = {
        show: this.props.show,
        value: dayjs().toDate(),
        desc_value: "",
    };

    onHide = () => {
        this.setState({ show: !true });
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    public locale = {
        sunday: "DOM",
        monday: "SEG",
        tuesday: "TER",
        wednesday: "QUA",
        thursday: "QUI",
        friday: "SEX",
        saturday: "SAB",
        ok: "Ok",
        today: "Hoje",
        yesterday: "Ontem",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
    };

    onConfirm = () => {
        if (this.state.desc_value && this.state.value) {
            if (this.props.onConfirm) {
                this.props.onConfirm(dayjs(this.state.value).format("DD/MM/YYYY"), this.state.desc_value);
            }
        } else {
            Notification.error({
                title:"Oops..",
                description: 'Preencha todos os campos!'
            })
        }
    };

    render() {
        return (
            <>
                <Modal show={this.state.show}>
                    <Modal.Header onHide={this.onHide}></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <FormGroup>
                                    <ControlLabel>Validade</ControlLabel>
                                    <DatePicker value={this.state.value} onChange={(value: any) => this.setState({ value })} locale={this.locale} format={"DD/MM/YYYY"}></DatePicker>
                                </FormGroup>
                            </div>
                            <div className="col-md-12">
                                <FormGroup>
                                    <ControlLabel>Descrição</ControlLabel>
                                    <Input componentClass={"textarea"} rows={3} onChange={(value: string) => this.setState({ desc_value: value })} value={this.state.desc_value} />
                                    {/* <WYSIWYG onChange={(v : any) => this.setState({desc_value: v})} value={this.state.desc_value} /> */}
                                    {/* <DatePicker value={this.state.value} onChange={(value: any) => this.setState({ value })} locale={this.locale} format={"DD/MM/YYYY"}></DatePicker> */}
                                </FormGroup>
                            </div>
                            {/* {this.state.desc_value} */}
                            {/* {JSON.stringify(this.props.file)} */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.onHide}>Cancelar</Button>
                        <Button onClick={this.onConfirm} color={"green"}>
                            Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export class UserFilesEvents extends Event {
    public show = false;

    public onBeforeUpload = (latestFile: any) => {
        if (latestFile && latestFile.id) {
            this.setFieldState("user_files", {
                show: true,
                latestFile,
            });
        }
    };

    public onConfirm = (value: string, fieldValue: string) => {
        this.setState({ loadings: ["user_files"] });
        axios
            .post("/api/v1/file/" + this.state.latestFile.id + "/validade", {
                value,
            })
            .then((res) => {
                axios
                    .post("/api/v1/file/" + this.state.latestFile.id + "/descricao", {
                        value: fieldValue,
                    })
                    .then((res) => {
                        var files = this.globalState.formValues["user_files"];
                        var file = this.state.latestFile;
                        file.values = {
                            validade: value,
                            descricao: fieldValue,
                        };
                        if (!files) {
                            files = [];
                        }
                        files.splice(files.length - 1, 1);
                        files.push(file);
                        //console.log(file, files);
                        this.setFieldValue("user_files", files);
                    })
                    .catch((e) => {})
                    .finally(() => {
                        this.setState({ loadings: [] });
                    });
            })
            .catch((e) => {});

        this.setFieldState("user_files", {
            show: false,
        });
    };

    public onCancel = () => {
        this.setState({ loadings: ["user_files"] });
        axios
            .post("/api/v1/file/delete/" + this.state.latestFile.id)
            .then((res) => {
                var oldFiles = this.globalState.formValues.user_files;
                if (oldFiles) {
                    for (var i in oldFiles) {
                        var file = oldFiles[i];
                        if (file.fileKey === this.state.latestFile._key) {
                            delete oldFiles[i];
                        }
                    }
                }
                //console.log("DELETING FILE",this.state.latestFile);
                this.setFieldValue(
                    "user_files",
                    oldFiles.filter((item: any) => item !== null),
                );
            })
            .catch((e) => {})
            .finally(() => {
                this.setState({ loadings: [] });
            });
        this.setFieldState("user_files", {
            show: false,
        });
    };

    public onRender = () => {
        if (this.state?.show) {
            return <UserFilesModalComponent onConfirm={this.onConfirm} onCancel={this.onCancel} show={true} />;
        }
    };
}
