import dayjs from "dayjs";
import { Event } from "../Event"
import axios from "../../utilities/axios";

export class StockEntrieEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
        if (this.globalProps.isEdit) {
            if (this.globalProps.values.stock_entrie_cancel_motive == null || this.globalProps.values.stock_entrie_cancel_motive === "") {
                this.setField("stock_entrie_cancel_motive", { hidden: true });
                this.setField("stock_entrie_canceled_by", { hidden: true });
                this.setField("stock_entrie_canceled_at", { hidden: true });
                // console.log("eventooo", this.globalProps.values.stock_entrie_cancel_motive);
            }
            if (!this.globalProps.values.stock_entrie_is_entrie) {
                this.setField("stock_entrie_user_fk_user_id", { readonly: true });
                this.setField("stock_entrie_tire_measurement_fk_tire_measurement_id", { readonly: true });
                this.setField("stock_entrie_quantity", { readonly: true });
                this.setField("stock_entrie_os_fk_service_order_id", { readonly: true });
                this.setField("stock_entrie_nf_number", { readonly: true });
                this.setField("stock_entrie_canceled_by", { readonly: true });
            }
        }

        // console.log("globalProps", this.globalProps);
        if (this.globalProps.match.url.includes('/add')) {
            this.setFieldValue("stock_entrie_is_entrie", true);

            this.setField("stock_entrie_nf_number", { width: 4 });

            this.setField("stock_entrie_created_at", { mask: ["99/99/9999"] });
            this.setField("stock_entrie_created_at", { readonly: false });
            this.setFieldValue("stock_entrie_created_at", dayjs().format('DD-MM-YYYY hh:mm:ss.sss'));
        }
    };
}

export class StockEntrieServiceRequestNumberEvents extends Event {
    public onChange = async (value: string, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState('loadings', ['stock_entrie_user_fk_user_id', 'stock_entrie_os_fk_service_order_id', 'stock_entrie_tire_measurement_fk_tire_measurement_id']);
            await axios.get("/api/v1/get-os-by-ss/" + value).then((res) => {
                if (res?.data?.data?.service_order_mechanics_fk_user_id) {
                    setField("stock_entrie_user_fk_user_id", res?.data?.data?.service_order_mechanics_fk_user_id);
                }
                if (res?.data?.data?.service_order_id) {
                    setField("stock_entrie_os_fk_service_order_id", res?.data?.data?.service_order_id);
                }
                if (res?.data?.data?.service_order_fk_tire_measurement_id) {
                    setField("stock_entrie_tire_measurement_fk_tire_measurement_id", res?.data?.data?.service_order_fk_tire_measurement_id);
                }
            }).finally(() => {
                this.setState('loadings', []);
            })
        }
    }
}