import { Event } from "../Event";
import axios from "../../utilities/axios";

export class BalanceAdjustmentWorkshopFkUserIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, setFieldValue: any) => {
        // this.setField("balance_adjustment_workshop_cnpj", "");

        this.setState("loadings", [
            "balance_adjustment_workshop_cnpj"
        ]);

        axios
            .get("/api/v1/users/" + value)
            .then((res) => {
                const { user_username } = res.data.item;
                // console.log({ user_username })
                this.setFieldValue("balance_adjustment_workshop_cnpj", user_username);

                this.setField("balance_adjustment_tire_measurement_fk_tire_measurement_id", {
                    api: {
                        ...this.globalProps.form.balance_adjustment_tire_measurement_fk_tire_measurement_id.api,
                        url: `/api/v1/picker-consigned/${this.globalProps.values.balance_adjustment_workshop_fk_user_id}?selectcomponent=true`
                    }
                });
                this.setField("balance_adjustment_tire_measurement_fk_tire_measurement_id", { readonly: false });
            })
            .catch((e) => {
                this.setState("loadings", []);
            })
            .finally(() => {
                this.setState("loadings", []);
            });
    }
};